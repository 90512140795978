import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import React from "react";
import { toast } from "react-hot-toast";

/** @category Prop Types */
export type ToastProps = {
  message: string;
  classNames?: string;
  support?: boolean;
};

export const SuccessToast: React.FC<ToastProps> = ({ message, classNames }) => {
  return (
    <div
      className={`rounded-md bg-green-50 p-4 ${classNames}`}
      data-testid="success-toast-container"
    >
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon
            className="h-5 w-5 text-green-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3" data-testid="success-toast-message-container">
          <p
            className="text-sm font-medium text-green-800"
            data-testid="success-toast-message"
          >
            {message}
          </p>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              data-testid="remove-toast-button"
              className="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
              onClick={() => toast.remove()}
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ErrorToast: React.FC<ToastProps> = ({
  message,
  classNames,
  support,
}) => {
  return (
    <div
      className={`rounded-md bg-red-100 p-4 ${classNames}`}
      data-testid="error-toast-container"
    >
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationCircleIcon
            className="h-5 w-5 text-red-500"
            aria-hidden="true"
          />
        </div>
        <div
          className="ml-3 flex-col"
          data-testid="error-toast-message-container"
        >
          <p
            className="text-sm font-medium text-red-800 z-max"
            data-testid="error-toast-message"
          >
            {message}
          </p>
          {support && (
            <p
              className="mt-2 text-sm font-medium text-red-800 z-max"
              data-testid="error-toast-support-message"
            >
              If you continue to have issues, please email{" "}
              <a href="mailto:support@rebootmotion.com">
                support@rebootmotion.com
              </a>{" "}
              for assistance.
            </p>
          )}
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              data-testid="remove-toast-button"
              className="inline-flex bg-red-100 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
              onClick={() => toast.remove()}
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const WarningToast: React.FC<ToastProps> = ({ message, classNames }) => {
  return (
    <div
      className={`rounded-md bg-yellow-100 p-4 ${classNames}`}
      data-testid="warning-toast-container"
    >
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon
            className="h-5 w-5 text-yellow-500"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3" data-testid="warning-toast-message-container">
          <p
            className="text-sm font-medium text-yellow-700 z-max"
            data-testid="warning-toast-message"
          >
            {message}
          </p>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              data-testid="remove-toast-button"
              className="inline-flex bg-yellow-100 rounded-md p-1.5 text-yellow-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yellow-50 focus:ring-yellow-600"
              onClick={() => toast.remove()}
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
