import { RegistrationDetails } from "../containers/Registration/Registration";
import { camelCaseResponse, toTitleCase } from "../shared/Functions";
import { User, UserLoginData } from "../types";
import {
  ApiCreateUser,
  ApiRegistrationData,
  ApiResetPasswordData,
  ApiResetTokenData,
  GenericApiResponse,
} from "../types/apiTypes";
import { emptyApi } from "./emptyApi";

export const userExtendedApi = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    loginUser: builder.mutation<User, UserLoginData>({
      query: (credentials) => ({
        url: "/login",
        method: "POST",
        body: credentials,
      }),
      transformResponse: (response: any) => camelCaseResponse(response),
    }),
    createUser: builder.mutation<User, ApiRegistrationData>({
      query: (data: ApiRegistrationData) => ({
        url: "/user",
        method: "POST",
        body: processRegistrationFormValues(data),
      }),
      transformResponse: (response: any) => camelCaseResponse(response),
    }),
    checkRegistrationToken: builder.query<RegistrationDetails, string>({
      query: (token: string) => `user/registration_token/${token}`,
      transformResponse: (response: any) => camelCaseResponse(response),
    }),
    requestPasswordReset: builder.mutation<
      ApiResetPasswordData,
      ApiResetPasswordData
    >({
      query: (data: ApiResetPasswordData) => ({
        url: "/user/reset_password",
        method: "PATCH",
        body: data,
      }),
      transformResponse: (response: any) => camelCaseResponse(response),
    }),
    checkResetToken: builder.query<ApiResetTokenData, string>({
      query: (token: string) => `/user/reset_token/${token}`,
      transformResponse: (response: any) => camelCaseResponse(response),
    }),
    setNewPassword: builder.mutation<GenericApiResponse, ApiResetPasswordData>({
      query: (data: ApiResetPasswordData) => ({
        url: `/user/set_new_password?rpt=${data.token}`,
        method: "PATCH",
        body: data,
      }),
      transformResponse: (response: any) => camelCaseResponse(response),
    }),
  }),
});

export const {
  useLoginUserMutation,
  useCreateUserMutation,
  useCheckRegistrationTokenQuery,
  useRequestPasswordResetMutation,
  useCheckResetTokenQuery,
  useSetNewPasswordMutation,
} = userExtendedApi;

function processRegistrationFormValues(data: ApiRegistrationData) {
  const { registrationFormValues, orgId, registrationToken } = data;
  const registrationData: ApiCreateUser = {
    first_name: toTitleCase(registrationFormValues.firstName),
    last_name: toTitleCase(registrationFormValues.lastName),
    email: registrationFormValues.email,
    password: registrationFormValues.password,
    registration_token: registrationToken,
    org_id: orgId,
  };
  return registrationData;
}
