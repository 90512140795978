import React from "react";

import { HeroIcon } from "../../types";

type QuickActionButtonProps = {
  action: string;
  Icon: HeroIcon;
  buttonDataTestid: string;
  handleClick: () => void;
};

export const QuickActionButton: React.FC<QuickActionButtonProps> = ({
  action,
  Icon,
  buttonDataTestid,
  handleClick,
}: QuickActionButtonProps): JSX.Element => {
  return (
    <button
      className="flex items-center justify-center border border-gray-200 rounded-lg shadow px-2 h-32 w-[195px] 2xl:w-56 mr-[14px] mb-[14px] hover:bg-gray-50"
      data-testid={buttonDataTestid}
      onClick={handleClick}
    >
      <div className="w-[60%] flex flex-col items-center justify-center">
        <Icon className="flex-shrink-0 h-8 w-8 text-secondary-900" />
        <span className="text-gray-500 text-base pt-2 font-bold">{action}</span>
      </div>
    </button>
  );
};
