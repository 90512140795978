import { format } from "date-fns";
import React, { ReactNode } from "react";

import { Session } from "../../types";

type MobileProcessingSessionsProps = {
  processingSessions: Session[];
};

export const MobileProcessingSessions: React.FC<
  MobileProcessingSessionsProps
> = ({ processingSessions }: MobileProcessingSessionsProps): JSX.Element => {
  return (
    <ul data-testid="mobile-sessions-processing-list">
      {processingSessions.map((session: Session, index) => {
        return (
          <li
            key={index}
            className="py-4 border-b border-gray-200 h-24 sm:px-4"
            data-testid={`mobile-session`}
          >
            <div className="flex justify-between mx-4 text-sm text-gray-500">
              <div>
                <p
                  className="text-gray-900"
                  data-testid={`session-${index}-date`}
                >
                  {format(new Date(session.sessionDate), "M/d/yyyy")}
                </p>
                {session.numberOfMovements.pitches &&
                  session.numberOfMovements.pitches !== 0 && (
                    <div
                      className="flex justify-begin items-center"
                      data-testid={`session-${index}-pitch-movements`}
                    >
                      <span className="font-medium py-1">Pitches: </span>
                      <p className="text-gray-900 pl-1">
                        {session.numberOfMovements.pitches}
                      </p>
                    </div>
                  )}
                {session.numberOfMovements.swings &&
                  session.numberOfMovements.swings !== 0 && (
                    <div
                      className="flex justify-begin items-center"
                      data-testid={`session-${index}-swing-movements`}
                    >
                      <span className="font-medium">Swings: </span>
                      <p className="text-gray-900 pl-1">
                        {session.numberOfMovements.swings}
                      </p>
                    </div>
                  )}
              </div>
              {session.statusComponent as ReactNode}
            </div>
          </li>
        );
      })}
    </ul>
  );
};
