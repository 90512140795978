import React from "react";

import { LoginForm } from "../../components";
import { currentYear } from "../../shared/Constants";

export const Login: React.FC = () => {
  return (
    <div
      className="min-h-screen min-w-full bg-gray-50 flex flex-col justify-center py-12 px-4 sm:px-6 lg:px-8"
      data-testid={"login-root-element"}
    >
      <div
        className="sm:mx-auto sm:w-full sm:max-w-md bg-primary-800 sm:rounded-lg sm:shadow-lg py-4"
        data-testid={"login-header"}
      >
        <img
          className="mx-auto h-12 w-auto"
          src="/RebootLogo.png"
          alt="Reboot Motion logo with the words Reboot and Motion stacked on top of each other with the top one bold and bottom one regular weight"
          data-testid="reboot-logo"
        />
        <h2
          className="mt-6 text-center text-3xl font-extrabold text-white"
          data-testid="login-heading"
        >
          Sign in to your account
        </h2>
      </div>
      <LoginForm />
      <div
        className="mt-6 text-center text-xs text-[#CACCCF]"
        data-testid="legal-notices-container"
      >
        <p>{`Copyright © ${currentYear} Reboot Motion, Inc.`}</p>
        <p>All rights reserved.</p>
        <p>
          <a
            href="https://rebootmotion.com/privacy"
            data-testid="privacy-link"
            target="_blank"
            rel="noreferrer"
          >
            Privacy
          </a>{" "}
          | {""}
          <a
            href="https://rebootmotion.com/terms"
            data-testid="terms-link"
            target="_blank"
            rel="noreferrer"
          >
            Terms
          </a>
        </p>
      </div>
    </div>
  );
};
