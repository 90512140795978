import { XMarkIcon } from "@heroicons/react/24/outline";
import { Column } from "@tanstack/react-table";
import { addDays, format, isSameDay } from "date-fns";
import React from "react";
import DatePicker from "react-datepicker";

import { CustomCalendarIcon, years } from "../../../shared/Constants";
import { classNames } from "../../../shared/Functions";
import { CustomDatePickerHeader } from "./CustomDatePickerHeader";

type MultiDatePickerProps = {
  selectedDates: any[];
  setSelectedDates: React.Dispatch<React.SetStateAction<any[]>>;
  segmentType?: string;
  onBlur?: () => void;
  column?: Column<Record<string, unknown>, unknown> | undefined;
  setDateFilter?: React.Dispatch<React.SetStateAction<string>>;
};

export const MultiDatePicker: React.FC<MultiDatePickerProps> = ({
  selectedDates,
  setSelectedDates,
  segmentType,
  onBlur,
  column,
  setDateFilter,
}: MultiDatePickerProps) => {
  const multipleDates = selectedDates
    .map((date) => format(date, "M/d/yyyy"))
    .join(", ");

  const handleMultipleDates = (date: any) => {
    const filteredDates = selectedDates.filter((d) => !isSameDay(d, date));
    if (filteredDates.length === selectedDates.length) {
      setSelectedDates([...selectedDates, date]);
      column?.setFilterValue([...selectedDates, date]);
    } else {
      setSelectedDates(filteredDates);
      column?.setFilterValue(filteredDates);
    }
  };

  return (
    <div
      className="flex items-center h-[38px] border border-[#DBDEE2] rounded-md"
      data-testid="multi-date-picker-container"
    >
      <DatePicker
        renderCustomHeader={({
          date,
          monthDate,
          customHeaderCount,
          decreaseMonth,
          increaseMonth,
          changeYear,
          nextMonthButtonDisabled,
        }) => (
          <CustomDatePickerHeader
            date={date}
            monthDate={monthDate}
            customHeaderCount={customHeaderCount}
            decreaseMonth={decreaseMonth}
            increaseMonth={increaseMonth}
            changeYear={changeYear}
            nextMonthButtonDisabled={nextMonthButtonDisabled}
            yearsDropdown={years}
            segmentType={segmentType}
            isMulti={true}
          />
        )}
        dropdownMode="select"
        value={multipleDates}
        highlightDates={selectedDates}
        id="multi-date-picker"
        name="multi-date-picker"
        placeholderText="Select Date(s)"
        maxDate={addDays(new Date(), 0)}
        shouldCloseOnSelect={false}
        dateFormat="M/d/yyyy"
        data-testid="multi-date-picker"
        className="font-medium text-base w-full text-gray-900 h-8 py-0.5 rounded-md border-none sm:text-sm cursor-default"
        onChange={(date) => {
          if (date) {
            handleMultipleDates(date);
          } else {
            setSelectedDates([]);
          }
        }}
        onBlur={onBlur}
      />
      <XMarkIcon
        className={classNames(
          !selectedDates.length
            ? "hidden"
            : "h-5 pr-2 mr-2.5 text-gray-400 cursor-pointer border-r border-[#D1D5DB] hover:text-gray-600"
        )}
        data-testid="clear-multi-dates-icon"
        onClick={() => {
          setSelectedDates([]);
          column?.setFilterValue("");
          setDateFilter && setDateFilter("");
        }}
      />
      <CustomCalendarIcon />
    </div>
  );
};
