import React from "react";
import Plot from "react-plotly.js";

type PlotlyProps = {
  data: any[];
  layout: any;
  frames?: any[];
};

export const PlotlyPlot: React.FC<PlotlyProps> = ({
  data,
  layout,
  frames,
}: PlotlyProps): JSX.Element => {
  return (
    <Plot
      data={data}
      layout={layout}
      frames={frames ? frames : []}
      useResizeHandler={true}
      style={{ width: "90vw", height: "75vh" }}
    />
  );
};
