import { format, parse } from "date-fns";

import {
  camelCaseResponse,
  providesList,
  toTitleCase,
} from "../shared/Functions";
import { Player, PlayerFormValues } from "../types";
import { emptyApi } from "./emptyApi";

export const playerExtendedApi = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    getPlayerById: builder.query<Player, string>({
      query: (id) => `/player/${id}`,
      transformResponse: (response: any) => {
        return parseSinglePlayer(camelCaseResponse(response));
      },
      providesTags: (result, error, id) => [{ type: "Players", id }],
    }),
    getAllPlayers: builder.query<Player[], { offset: number; limit: number }>({
      query: (args) => {
        const { offset, limit } = args;
        return {
          url: "/players?sort_by=alpha",
          params: { offset, limit },
        };
      },
      transformResponse: (response: any) => {
        return parsePlayerData(camelCaseResponse(response));
      },
      providesTags: (results) => providesList(results, "Players"),
    }),
    createPlayer: builder.mutation({
      query: (playerData) => ({
        url: "/player",
        method: "POST",
        body: processPlayerFormValues(playerData),
      }),
      transformResponse: (response: any) => camelCaseResponse(response),
      invalidatesTags: ["Players", "Org"],
    }),
    editPlayer: builder.mutation({
      query: (args) => {
        const { orgPlayerId } = args;
        return {
          url: `/player/${orgPlayerId}`,
          method: "PATCH",
          body: processPlayerFormValues(args),
        };
      },
      transformResponse: (response: any) => camelCaseResponse(response),
      invalidatesTags: (result, error, arg) => [
        { type: "Players", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetPlayerByIdQuery,
  useGetAllPlayersQuery,
  useCreatePlayerMutation,
  useEditPlayerMutation,
} = playerExtendedApi;

function parseSinglePlayer(player: Player): Player {
  const attributes: any = player.attributes;
  const domHandHitting = attributes.find(
    (attribute: { key: string }) => attribute.key === "dom_hand_hitting"
  );
  const domHandThrowing = attributes.find(
    (attribute: { key: string }) => attribute.key === "dom_hand_throwing"
  );
  const heightAttribute = attributes.find((attribute: { key: string }) =>
    attribute.key.includes("height_ft")
  );
  const weightAttribute = attributes.find((attribute: { key: string }) =>
    attribute.key.includes("weight_lbs")
  );
  const heightFeet = heightAttribute
    ? Math.floor(heightAttribute.value).toString()
    : "";
  const heightInches = heightAttribute
    ? Math.round(
        (heightAttribute.value - Math.floor(heightAttribute.value)) * 12
      ).toString()
    : "";
  const weight = weightAttribute
    ? Math.round(weightAttribute.value).toString()
    : "";
  const determineHandedness = (domHand: string): string => {
    if (domHand === "RHA") {
      return "Righty";
    } else if (domHand === "LHA") {
      return "Lefty";
    } else if (domHand === "SHA") {
      return "Switch";
    } else {
      return "";
    }
  };
  return {
    firstName: toTitleCase(player.firstName),
    lastName: toTitleCase(player.lastName),
    middleName: player.middleName === null ? "" : player.middleName,
    throws: domHandThrowing ? determineHandedness(domHandThrowing.value) : "",
    hits: domHandHitting ? determineHandedness(domHandHitting.value) : "",
    height: heightAttribute
      ? {
          feet: heightFeet,
          inches: heightInches,
        }
      : { feet: "", inches: "" },
    heightFeet: heightAttribute ? heightAttribute.value : "",
    weight: weightAttribute ? weight : "",
    dateOfBirth:
      player.dateOfBirth !== null
        ? format(
            parse(player.dateOfBirth, "yyyy-MM-dd", new Date()),
            "M/d/yyyy"
          )
        : "Unknown",
    playersTableValues: {
      playerName: `${toTitleCase(player.firstName)} ${toTitleCase(
        player.lastName
      )}`,
      height: heightAttribute ? `${heightFeet} ft ${heightInches} in` : "",
      weight: weightAttribute ? `${weight} lbs` : "",
    },
    id: player.id,
    orgPlayerId: player.orgPlayerId,
    officialLeagueId:
      player.officialLeagueId !== null ? player.officialLeagueId : "",
  };
}

export function parsePlayerData(responseToParse: Player[]): Player[] {
  return responseToParse.map((player: Player) => {
    return parseSinglePlayer(player);
  });
}

function processPlayerFormValues(data: {
  formValues: PlayerFormValues;
  orgId: string;
}) {
  const { formValues, orgId } = data;
  const {
    firstName,
    middleName,
    lastName,
    dateOfBirth,
    heightFeet,
    heightInches,
    hits,
    throws,
    weight,
    officialLeagueId,
    orgPlayerId,
  } = formValues;
  const determineDomHand = (domHand: string) => {
    if (domHand.toLowerCase() === "righty") {
      return "RHA";
    } else if (domHand.toLowerCase() === "lefty") {
      return "LHA";
    } else if (domHand.toLowerCase() === "switch") {
      return "SHA";
    }
  };
  const inchesToDecimalFeet = (parseInt(heightInches) / 12).toFixed(3);
  const decimalFeet = inchesToDecimalFeet.toString().substring(1);
  const totalHeight = (parseInt(heightFeet) + decimalFeet).toString();
  return {
    first_name: toTitleCase(firstName),
    middle_name:
      middleName && middleName !== "" ? toTitleCase(middleName) : null,
    last_name: toTitleCase(lastName),
    date_of_birth: dateOfBirth ? format(dateOfBirth, "yyyy-MM-dd") : new Date(),
    attributes: {
      dom_hand_hitting: determineDomHand(hits.value),
      dom_hand_throwing: determineDomHand(throws.value),
      height_ft: totalHeight,
      weight_lbs: weight,
    },
    org_id: orgId,
    org_player_id: orgPlayerId !== "" ? orgPlayerId : null,
    official_league_id: officialLeagueId !== "" ? officialLeagueId : null,
  };
}
