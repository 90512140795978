import { Header } from "@tanstack/react-table";
import React, { useState } from "react";

import { DateRangePicker, MultiDatePicker, SessionDateFilter } from "../shared";
import { SessionTypeFilter } from "../shared/components/SessionTypeFilter";

type SessionsTableFilterProps = {
  headers: Header<Record<string, unknown>, unknown>[];
  filters: string[];
};

export const SessionsTableFilter: React.FC<SessionsTableFilterProps> = ({
  headers,
  filters,
}: SessionsTableFilterProps) => {
  const [startDate, setStartDate] = useState<null | Date>(null);
  const [endDate, setEndDate] = useState<null | Date>(null);
  const [dateFilter, setDateFilter] = useState<string>("");
  const [selectedDates, setSelectedDates] = useState<any[]>([]);

  const sessionTypeHeader = headers.find(
    (header) => header.id === "typeAndVenue"
  );

  const sessionDateHeader = headers.find(
    (header) => header.id === "sessionDate"
  );

  return (
    <div className="flex w-2/3 ml-2">
      {filters.includes("sessionDate") && (
        <SessionDateFilter
          filterValue={dateFilter}
          setDateFilter={setDateFilter}
          column={sessionDateHeader?.column}
        />
      )}
      {filters.includes("sessionDate") && dateFilter === "Custom Range" && (
        <div className="ml-2 w-64">
          <DateRangePicker
            placeholderText="Select Range"
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            column={sessionDateHeader?.column}
            setDateFilter={setDateFilter}
          />
        </div>
      )}
      {filters.includes("sessionDate") && dateFilter === "Individual Dates" && (
        <div className="ml-2 w-[228px]">
          <MultiDatePicker
            selectedDates={selectedDates}
            setSelectedDates={setSelectedDates}
            column={sessionDateHeader?.column}
            setDateFilter={setDateFilter}
          />
        </div>
      )}
      {filters.includes("sessionType") && (
        <SessionTypeFilter sessionTypeHeader={sessionTypeHeader} />
      )}
    </div>
  );
};

export const filterSessionDates = (
  row: { getValue: (arg0: any) => any },
  columnId: string,
  filterValue: string | any[]
): any => {
  const cellValue = row.getValue(columnId);

  if (typeof filterValue === "string") {
    if (filterValue.includes(" - ")) {
      const dateRange = filterValue.split(" - ");
      const dates = dateRange.map((date) => new Date(date).toISOString());
      const [start, end] = dates;
      if (cellValue >= start && cellValue <= end) {
        return true;
      } else {
        return false;
      }
    } else if (cellValue !== new Date(filterValue).toISOString()) {
      return false;
    } else {
      return true;
    }
  } else if (Array.isArray(filterValue)) {
    if (filterValue.length === 1) {
      const date = filterValue[0].toISOString();
      if (cellValue !== date) {
        return false;
      }
    } else if (filterValue.length === 2) {
      const [start, end] = filterValue;
      const cellDate = new Date(cellValue);
      if (start && !end) {
        return cellDate.getTime() >= start.getTime();
      } else if (start && end) {
        const dates = filterValue.map((date: any) => date.toISOString());
        const startValue = start.toISOString();
        const endValue = end.toISOString();
        if (cellValue >= startValue && cellValue <= endValue) {
          return true;
        } else if (startValue >= endValue && dates.includes(cellValue)) {
          return true;
        } else {
          return false;
        }
      }
    } else if (filterValue.length > 2) {
      const multipleDates = filterValue.map((date: any) => date.toISOString());
      if (multipleDates.includes(cellValue)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
};
