/** @module Components */

import React from "react";

import { ReactComponent as Step1CircleActive } from "../../assets/StepperAssets/Step1CircleActive.svg";
import { ReactComponent as Step2CircleActive } from "../../assets/StepperAssets/Step2CircleActive.svg";
import { ReactComponent as Step2CircleInactive } from "../../assets/StepperAssets/Step2CircleInactive.svg";
import { ReactComponent as Step3CircleActive } from "../../assets/StepperAssets/Step3CircleActive.svg";
import { ReactComponent as Step3CircleInactive } from "../../assets/StepperAssets/Step3CircleInactive.svg";
import { ReactComponent as StepCircleComplete } from "../../assets/StepperAssets/StepCircleComplete.svg";

/** @category Prop Types */
export type StepperProgressProps = {
  stepTitles: Array<string>;
  currentStep: number;
};

export const StepperProgress: React.FC<StepperProgressProps> = ({
  stepTitles,
  currentStep,
}) => {
  return (
    <div
      className="flex justify-evenly p-5 bg-gray-100 w-full"
      data-testid="stepper-progress-container"
    >
      <div className="flex flex-col w-1/3 sm:w-auto sm:flex-row items-center">
        {currentStep === 1 ? (
          <Step1CircleActive data-testid="step-1-active-icon" />
        ) : (
          <StepCircleComplete />
        )}
        <h5
          id="step-1-title"
          data-testid="step-1-title"
          className={
            currentStep === 1
              ? "sm:pl-4 text-secondary-800 font-bold flex flex-col text-[12px] sm:text-sm mt-1 sm:mt-0 text-center sm:text-left"
              : "text-gray-900 sm:pl-4 font-bold flex flex-col text-[12px] sm:text-sm mt-1 sm:mt-0 text-center sm:text-left"
          }
        >
          {stepTitles[0]}
        </h5>
      </div>
      <div className="flex flex-col w-1/3 sm:w-auto sm:flex-row items-center sm:pl-4 md:pl-6 lg:pl-8 xl:pl-12">
        {currentStep === 2 ? (
          <Step2CircleActive data-testid="step-2-active-icon" />
        ) : currentStep < 2 ? (
          <Step2CircleInactive data-testid="step-2-inactive-icon" />
        ) : (
          <StepCircleComplete />
        )}
        <h5
          id="step-2-title"
          data-testid="step-2-title"
          className={
            currentStep === 2
              ? "sm:pl-4 text-secondary-800 font-bold text-[12px] sm:text-sm mt-1 sm:mt-0 text-center sm:text-left"
              : currentStep === 1
              ? "sm:pl-4 text-gray-400 font-bold text-[12px] sm:text-sm mt-1 sm:mt-0 text-center sm:text-left"
              : "text-gray-900 sm:pl-4 font-bold text-[12px] sm:text-sm mt-1 sm:mt-0 text-center sm:text-left"
          }
        >
          {stepTitles[1]}
        </h5>
      </div>
      {stepTitles.length > 2 && (
        <div className="flex flex-col w-1/3 sm:w-auto sm:flex-row items-center sm:pl-4 sm:ml-8 md:pl-6 lg:pl-8 xl:pl-12">
          {currentStep === 3 ? (
            <Step3CircleActive data-testid="step-3-active-icon" />
          ) : (
            <Step3CircleInactive data-testid="step-3-inactive-icon" />
          )}
          <h5
            id="step-3-title"
            data-testid="step-3-title"
            className={`${
              currentStep === 3
                ? "sm:pl-4 text-secondary-800 font-bold text-[12px] sm:text-sm mt-1 sm:mt-0 text-center sm:text-left"
                : "sm:pl-4 text-gray-400 font-bold text-[12px] sm:text-sm mt-1 sm:mt-0 text-center sm:text-left"
            }`}
          >
            {stepTitles[2]}
          </h5>
        </div>
      )}
    </div>
  );
};
