import { format } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";

import { Table } from "..";
import { PlayersTabProps } from "../../containers/PlayersPage/PlayersPage";
import { useAppContext } from "../../context";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { playerGroupSlice } from "../../redux/slices/playerGroupSlice";
import { playerSlice } from "../../redux/slices/playerSlice";
import { useGetAllPlayersQuery, useGetPlayerGroupsQuery } from "../../services";
import { columnHelper } from "../../shared/columns/SharedSessionColumns";
import { playerListLimit } from "../../shared/Constants";
import {
  ColumnOneTableCellSkeleton,
  SecondaryButton,
  TableCellSkeleton,
} from "../shared";

export const PlayerGroups: React.FC<PlayersTabProps> = ({
  offsetForPlayerList,
  setOffsetForPlayerList,
  playerQueryCount,
  setPlayerQueryCount,
  combinedPlayers,
  setCombinedPlayers,
  numberOfQueriesForPlayers,
}: PlayersTabProps): JSX.Element => {
  // State and AppContext
  const { setDisplayBack, setDialogState } = useAppContext();
  const [selectedPageSize, setSelectedPageSize] = useState(
    sessionStorage.getItem("processedSessionPageSize") || "10"
  );

  // RTK slice actions
  const dispatch = useAppDispatch();
  const { storePlayers } = playerSlice.actions;
  const { storePlayerGroup } = playerGroupSlice.actions;

  // Redux calls
  const playerGroupState = useAppSelector((state) => state.playerGroup);
  const storedPlayerList = useAppSelector((state) => state.players.playerList);
  const playerCount = useAppSelector((state) => state.org).players.total;

  // RTK Queries
  const { data: playerGroupData, isFetching } = useGetPlayerGroupsQuery();
  const {
    data: playerList,
    originalArgs,
    isFetching: fetchingPlayers,
  } = useGetAllPlayersQuery(
    {
      offset: offsetForPlayerList,
      limit: playerListLimit,
    },
    {
      skip:
        storedPlayerList.length === playerCount ||
        playerQueryCount === numberOfQueriesForPlayers,
    }
  );

  const openPlayerGroupModal = (playerGroup: any) => {
    dispatch(
      storePlayerGroup({
        ...playerGroupState,
        modalOpenState: true,
        groupName: playerGroup.name,
        groupId: playerGroup.groupId,
      })
    );
  };

  useEffect(() => {
    if (playerList && originalArgs) {
      if (playerList.length === playerListLimit) {
        setOffsetForPlayerList(originalArgs.offset + playerListLimit);
        setCombinedPlayers(combinedPlayers.concat(playerList));
        setPlayerQueryCount(playerQueryCount + 1);
      } else if (playerList.length < playerListLimit) {
        setCombinedPlayers(combinedPlayers.concat(playerList));
        setOffsetForPlayerList(0);
        setPlayerQueryCount(playerQueryCount + 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerList]);

  useEffect(() => {
    if (storedPlayerList.length !== playerCount && combinedPlayers) {
      dispatch(storePlayers(combinedPlayers));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [combinedPlayers]);

  const playerGroupColumns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Group Name",
        cell: function nameCell(element: any) {
          const group = element.row.original;
          return (
            <button
              className="font-bold text-primary-700 cursor-pointer hover:underline"
              data-testid="player-group-name"
              onClick={() => openPlayerGroupModal(group)}
            >
              {element.getValue()}
            </button>
          );
        },
      }),
      columnHelper.accessor("createdAt", {
        header: "Date Created",
        sortingFn: "datetime",
        cell: function dateCell(element: any) {
          return (
            <div
              className="font-medium text-gray-900 cursor-default"
              data-testid="player-group-date-created"
            >
              {format(new Date(element.getValue()), "M/d/yyyy")}
            </div>
          );
        },
      }),
      columnHelper.accessor("edit", {
        header: "",
        enableSorting: false,
        cell: function actionsCell(element) {
          const group = element.row.original;
          return (
            <div className="flex" data-testid="edit-player-group-container">
              <SecondaryButton
                data-testid="edit-player-group-button"
                className="ml-4"
                onClick={() => openPlayerGroupModal(group)}
              >
                Edit
              </SecondaryButton>
            </div>
          );
        },
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const tableData = useMemo(
    () =>
      isFetching
        ? Array(10).fill({})
        : playerGroupData
        ? [...playerGroupData].sort((a: any, b: any) =>
            a.createdAt > b.createdAt ? -1 : 1
          )
        : [],
    [isFetching, playerGroupData]
  );

  const tableColumns = useMemo(
    () =>
      isFetching
        ? playerGroupColumns.map((column, index) => ({
            ...column,
            cell:
              index === 0 ? (
                <ColumnOneTableCellSkeleton />
              ) : (
                <TableCellSkeleton />
              ),
          }))
        : playerGroupColumns,
    [isFetching, playerGroupColumns]
  );

  const buttonAction = (event: any) => {
    event.preventDefault();
    setDialogState({
      shouldOpen: true,
      type: "group",
    });
  };

  useEffect(() => {
    setDisplayBack && setDisplayBack(false);
  }, [setDisplayBack]);

  return (
    <main className="relative pb-20 z-0 overflow-y-auto w-full">
      <div
        className="block min-w-max"
        data-testid="player-groups-table-container"
      >
        <Table
          columns={tableColumns}
          data={tableData}
          data-testid="player-groups-table"
          selectedPageSize={selectedPageSize}
          setSelectedPageSize={setSelectedPageSize}
          displayFooter={true}
          fetching={isFetching}
          customRowClassName="player-groups-table-row"
          component="playerGroups"
          disableButton={fetchingPlayers}
          buttons={[
            {
              type: "primary",
              action: buttonAction,
              text: "Create Player Group",
              dataTestid: "create-player-group-button",
            },
          ]}
        />
      </div>
    </main>
  );
};
