import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import React from "react";

import { useAppContext } from "../../context";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

import {
  DataExportFile,
  dataExportPopupSlice,
} from "../../redux/slices/dataExportPopupSlice";
import { dataExportSlice } from "../../redux/slices/dataExportSlice";
import { toTitleCase } from "../../shared/Functions";
import { BlankButton, PrimaryButton } from "../shared";

export const DataExportModal: React.FC = (): JSX.Element => {
  const { setModalState, setExportPopupState, setExportInProgress } =
    useAppContext();

  // Redux calls
  const dataExportState = useAppSelector((state) => state.dataExport);
  const orgData = useAppSelector((state) => state.org);
  const dataExportFiles = useAppSelector(
    (state) => state.dataExportPopup.files
  );

  // RTK slice actions
  const dispatch = useAppDispatch();
  const { storeDataExport, clearDataExport } = dataExportSlice.actions;
  const { storeDataExportFiles } = dataExportPopupSlice.actions;

  const dataTypes = [
    {
      id: "inverse-kinematics",
      value: "Inverse Kinematics",
      label: "Inverse Kinematics Files",
    },
    {
      id: "momentum-energy",
      value: "Momentum",
      label: "Momentum Files",
    },
    { id: "metadata", value: "Metadata", label: "Metadata" },
  ];

  const movementTypes = orgData.details.movementTypes.map((movement) => {
    const type = toTitleCase(movement.slug);
    if (type.includes("Baseball") || type.includes("Basketball")) {
      const splitType = type.split(" ");
      return { id: `${movement.id}`, value: `${splitType[1]}` };
    } else {
      return { id: `${movement.id}`, value: type };
    }
  });

  const handleMovementTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      dataExportState.movementTypes.some(
        (movement) => movement.id === e.target.id
      )
    ) {
      const updatedMovementTypes = dataExportState.movementTypes.filter(
        (m) => m.id !== e.target.id
      );
      dispatch(
        storeDataExport({
          ...dataExportState,
          movementTypes: updatedMovementTypes,
        })
      );
    } else {
      dispatch(
        storeDataExport({
          ...dataExportState,
          movementTypes: [
            ...dataExportState.movementTypes,
            { id: e.target.id, value: e.target.value },
          ],
        })
      );
    }
  };

  const handleDataTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      dataExportState.dataTypes.some((data) => data.value === e.target.value)
    ) {
      const updatedDataTypes = dataExportState.dataTypes.filter(
        (d) => d.value !== e.target.value
      );
      dispatch(
        storeDataExport({
          ...dataExportState,
          dataTypes: updatedDataTypes,
        })
      );
    } else {
      dispatch(
        storeDataExport({
          ...dataExportState,
          dataTypes: [
            ...dataExportState.dataTypes,
            { value: e.target.value, id: e.target.id },
          ],
        })
      );
    }
  };

  const openPopup = () => {
    const filesToExport: DataExportFile[] = [];

    dataExportState.sessionList.forEach((session) => {
      if (
        dataExportState.movementTypes.length === 1 &&
        dataExportState.dataTypes.length === 1
      ) {
        const newFile = {
          orgPlayerId: dataExportState.orgPlayerId,
          sessionId: session.id,
          movementType: dataExportState.movementTypes[0],
          dataType: dataExportState.dataTypes[0],
          dataFormat: dataExportState.dataFormat,
          sessionDate: session.date,
          playerName: dataExportState.playerName,
        };
        filesToExport.push(newFile);
      } else {
        dataExportState.movementTypes.forEach((movementType) => {
          dataExportState.dataTypes.forEach((dataType) => {
            const newFile = {
              orgPlayerId: dataExportState.orgPlayerId,
              sessionId: session.id,
              movementType: movementType,
              dataType: dataType,
              dataFormat: dataExportState.dataFormat,
              sessionDate: session.date,
              playerName: dataExportState.playerName,
            };
            filesToExport.push(newFile);
          });
        });
      }
    });

    let numberOfFiles: number;

    if (dataExportFiles.length > 0) {
      const existingAndNewFiles = filesToExport.concat([...dataExportFiles]);
      const filesWithoutAStatus = existingAndNewFiles.filter(
        (file) => !file.status
      );
      numberOfFiles = filesWithoutAStatus.length;
      dispatch(storeDataExportFiles({ files: existingAndNewFiles }));
    } else {
      numberOfFiles = filesToExport.length;
      dispatch(storeDataExportFiles({ files: filesToExport }));
    }

    const filesSingularOrPlural = numberOfFiles === 1 ? "Export" : "Exports";
    setExportPopupState({
      shouldOpen: true,
      title: `Preparing ${numberOfFiles} ${filesSingularOrPlural}`,
    });
    sessionStorage.setItem("exportPopupState", "true");
    sessionStorage.setItem(
      "exportPopupTitle",
      `Preparing ${numberOfFiles} ${filesSingularOrPlural}`
    );

    setModalState(false);
    setExportInProgress(true);
    dispatch(clearDataExport());
  };

  return (
    <div className="data-export-container">
      <div
        className="bg-[#F9FAFB] flex justify-center p-4 space-x-40 max-h-52 overflow-scroll"
        data-testid="data-export-header"
      >
        <div className="space-y-3 text-sm leading-4">
          <p className="text-gray-500" data-testid="data-export-player-header">
            Player
          </p>
          <p data-testid="data-export-player">{dataExportState.playerName}</p>
        </div>
        <div className="space-y-3 text-sm leading-4">
          <p
            className="text-gray-500"
            data-testid="data-export-session-id-header"
          >
            Session ID
          </p>
          {dataExportState.sessionList.map((session) => (
            <div className="flex" key={session.id}>
              <p data-testid="data-export-session-id">{session.id}</p>
            </div>
          ))}
        </div>
        <div className="space-y-3 text-sm leading-4">
          <p
            className="text-gray-500"
            data-testid="data-export-session-date-header"
          >
            Session Date
          </p>
          {dataExportState.sessionList.map((session) => (
            <div className="flex" key={session.id}>
              <p data-testid="data-export-session-date">{session.date}</p>
            </div>
          ))}
        </div>
      </div>

      <div
        className="h-56 p-8 flex justify-center space-x-40"
        data-testid="data-export-options"
      >
        <div
          className="flex flex-col space-y-3"
          data-testid="movement-type-checkboxes"
        >
          <p
            className="text-gray-800 text-lg"
            data-testid="data-export-movement-type-header"
          >
            Select Movement Type
          </p>
          {movementTypes.map((movement) => (
            <div
              className="text-gray-600 flex items-center"
              key={movement.id}
              data-testid="data-export-movement-type"
            >
              <input
                type="checkbox"
                className="border-gray-300 rounded-[4px] mr-4 checked:bg-[#FB6A07] checked:focus:bg-[#FB6A07] checked:hover:bg-[#FB6A07] focus:ring-[#FB6A07] focus:ring-opacity-50 focus:ring-2"
                onChange={handleMovementTypeChange}
                value={movement.value}
                id={movement.id}
                data-testid="movement-type-checkbox"
                checked={dataExportState.movementTypes.some(
                  (type) => type.id === movement.id
                )}
              />
              <label className="text-sm">{movement.value}</label>
            </div>
          ))}
        </div>
        <div
          className="flex flex-col space-y-3"
          data-testid="data-type-checkboxes"
        >
          <p
            className="text-gray-800 text-lg"
            data-testid="data-export-data-type-header"
          >
            Select Data Type
          </p>
          {dataTypes.map((data, index) => (
            <div
              className="text-gray-600 flex items-center"
              key={index}
              data-testid="data-export-data-type"
            >
              <input
                type="checkbox"
                className="border-gray-300 rounded-[4px] mr-4 checked:bg-[#FB6A07] checked:focus:bg-[#FB6A07] checked:hover:bg-[#FB6A07] focus:ring-[#FB6A07] focus:ring-opacity-50 focus:ring-2"
                onChange={handleDataTypeChange}
                id={data.id}
                value={data.value}
                data-testid="data-type-checkbox"
                checked={dataExportState.dataTypes.some(
                  (type) => type.value === data.value
                )}
              />
              <label className="text-sm">{data.label}</label>
            </div>
          ))}
        </div>
      </div>

      <footer
        data-testid="data-export-footer"
        className="flex justify-end mb-5 pt-5 pr-5 w-full border-t border-gray-200"
      >
        <BlankButton
          data-testid="cancel-export-button"
          type="button"
          onClick={() => {
            setModalState(false);
            dispatch(clearDataExport());
          }}
        >
          Cancel
        </BlankButton>
        <PrimaryButton
          data-testid="prepare-export-button"
          className="ml-3 flex"
          onClick={openPopup}
          disabled={
            dataExportState.movementTypes.length === 0 ||
            dataExportState.dataTypes.length === 0
          }
        >
          <ArrowDownTrayIcon className="h-5 w-5 mr-2 stroke-2" />
          Export Data
        </PrimaryButton>
      </footer>
    </div>
  );
};
