import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { RootState } from "../redux/store";

export function determineBaseUrl(): string {
  if (process.env.REACT_APP_RUN_ENV === "env-stage") {
    return "https://env-stage-api.rebootmotion.com/";
  } else if (process.env.REACT_APP_RUN_ENV === "env-prod") {
    return "https://api.rebootmotion.com/";
  }
  return "http://localhost:8000/";
}

export const emptyApi = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: determineBaseUrl(),
    prepareHeaders: (headers, { getState }) => {
      const authToken = (getState() as RootState).user.token;
      if (authToken) {
        headers.set("authorization", `Bearer ${authToken}`);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
  tagTypes: [
    "Sessions",
    "User",
    "Players",
    "Org",
    "UploadSession",
    "Reports",
    "ApiKeys",
    "RequestedAnalyses",
    "PlayerGroups",
    "PlayerGroupById",
  ],
});
