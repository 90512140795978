import {
  ChevronDownIcon,
  ChevronUpIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import React from "react";

import { isMobile } from "react-device-detect";
import { ReactComponent as RebootMotionLogo } from "../../assets/RebootMotionLogo.svg";
import { classNames } from "../../shared/Functions";

type ModalHeaderProps = {
  title: string;
  handleClose: () => void;
  component: string;
  minimizePopup?: boolean;
  toggleMinimize?: () => void;
};

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  title,
  handleClose,
  component,
  minimizePopup,
  toggleMinimize,
}: ModalHeaderProps) => {
  return (
    <div
      className="w-full h-20 bg-primary-800 text-xl text-white font-bold flex justify-between items-center px-4 sticky top-0 z-50"
      data-testid={`${component}-modal-header`}
    >
      {component === "player-group" ? (
        <div className="flex items-center ml-2">
          <RebootMotionLogo />
          <h2 className="ml-8" data-testid={`${component}-modal-title`}>
            {title}
          </h2>
        </div>
      ) : component.includes("popup") ? (
        <div
          className="flex w-full justify-between mr-4"
          data-testid="popup-title-container"
        >
          <h3 className="ml-2" data-testid={`${component}-title`}>
            {title}
          </h3>
          <button data-testid="minimize-button" onClick={toggleMinimize}>
            {!minimizePopup ? (
              <ChevronDownIcon
                className={classNames(isMobile ? "hidden" : "", "w-6 stroke-2")}
              />
            ) : (
              <ChevronUpIcon className="w-6 stroke-2" />
            )}
          </button>
        </div>
      ) : (
        <h2 className="ml-2" data-testid={`${component}-modal-title`}>
          {title}
        </h2>
      )}
      <button
        className="mr-2"
        id={`${component}-modal-close-button`}
        data-testid={`${component}-modal-close-button`}
        onClick={handleClose}
      >
        <XMarkIcon className="h-6 w-6 text-white stroke-2" />
      </button>
    </div>
  );
};
