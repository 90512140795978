/** @module Containers */

import { format, parseISO } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";

import { ApiKeyDialog, ApiKeyModal, Table } from "../../components";
import {
  ErrorToast,
  PrimaryButton,
  RedButton,
  TableCellSkeleton,
} from "../../components/shared";
import { useAppContext } from "../../context";
import {
  useCreateApiKeyMutation,
  useGetApiKeysQuery,
} from "../../services/apiKey";
import { columnHelper } from "../../shared/columns/SharedSessionColumns";
import { ApiKeyData } from "../../types";

export const ApiKey: React.FC = (): JSX.Element => {
  // State and AppContext
  const [activeKeys, setActiveKeys] = useState<ApiKeyData[]>([]);
  const [deactivatedKeys, setDeactivatedKeys] = useState<ApiKeyData[]>([]);
  const [keyId, setKeyId] = useState<number>(0);
  const [action, setAction] = useState<string>("");
  const {
    setModalState,
    setModalTitle,
    setModalSingleStep,
    dialogState,
    setDialogState,
  } = useAppContext();
  const [disableBtn, setDisableBtn] = useState<boolean>(true);

  // RTK Queries
  const [createApiKey] = useCreateApiKeyMutation();
  const {
    data: apiKeysData,
    isFetching: fetchingKeys,
    refetch: refetchKeys,
  } = useGetApiKeysQuery(undefined);

  const errorToast = (message: string) =>
    toast.custom(<ErrorToast message={message} classNames="mt-modal" />, {
      id: "generateApiKeyError",
    });

  const activeApiKeyColumns = useMemo(
    () => [
      columnHelper.accessor("keyDisplayValue", {
        header: "API Key",
        enableSorting: false,
        cell: function apiKeyCell(element: any) {
          return (
            <p
              className="text-gray-900 font-bold"
              data-testid="api-key-value"
            >{`***********************${element.row.original.keyDisplayValue}`}</p>
          );
        },
      }),
      columnHelper.accessor("createdAt", {
        header: "Created",
        enableSorting: false,
        cell: function createdCell(element: any) {
          const createdAtDate = format(
            parseISO(element.row.original.createdAt),
            "M/d/yyyy"
          );
          return (
            <p className="text-gray-900" data-testid="api-key-created-date">
              {createdAtDate}
            </p>
          );
        },
      }),
      columnHelper.accessor("keyActions", {
        header: "",
        enableSorting: false,
        cell: function createdCell(element: any) {
          return (
            <div className="flex justify-end mr-10">
              <RedButton
                data-testid="deactivate-button"
                onClick={() => openDeactivateDialog(element.row.original.id)}
              >
                Deactivate
              </RedButton>
            </div>
          );
        },
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const deactivatedApiKeyColumns = useMemo(
    () => [
      activeApiKeyColumns[0],
      activeApiKeyColumns[1],
      columnHelper.accessor("keyActions", {
        header: "",
        enableSorting: false,
        cell: function createdCell(element: any) {
          return (
            <div className="flex justify-end mr-10">
              <RedButton
                data-testid="delete-button"
                onClick={() => openDeleteDialog(element.row.original.id)}
              >
                Delete
              </RedButton>
            </div>
          );
        },
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const activeKeyTableData = useMemo(
    () => (fetchingKeys ? Array(1).fill({}) : activeKeys),
    [fetchingKeys, activeKeys]
  );

  const activeKeyTableColumns = useMemo(
    () =>
      fetchingKeys
        ? activeApiKeyColumns.map((column) => ({
            ...column,
            cell: <TableCellSkeleton />,
          }))
        : activeApiKeyColumns,
    [fetchingKeys, activeApiKeyColumns]
  );

  const deactivatedKeyTableData = useMemo(
    () => (fetchingKeys ? Array(1).fill({}) : deactivatedKeys),
    [fetchingKeys, deactivatedKeys]
  );

  const deactivatedKeyTableColumns = useMemo(
    () =>
      fetchingKeys
        ? deactivatedApiKeyColumns.map((column) => ({
            ...column,
            cell: <TableCellSkeleton />,
          }))
        : deactivatedApiKeyColumns,
    [fetchingKeys, deactivatedApiKeyColumns]
  );

  const generateKeyAndOpenModal = () => {
    createApiKey("")
      .unwrap()
      .then((response) => {
        const apiKey: string = response.apiKey;
        if (apiKey) {
          setModalTitle("API Key successfully created");
          setModalState(true);
          setModalSingleStep(<ApiKeyModal apiKey={apiKey} />);
          refetchKeys();
        } else {
          errorToast("There was an error generating the API Key.");
        }
      })
      .catch(() => {
        errorToast("There was an error generating the API Key.");
      });
  };

  const openDeactivateDialog = (id: number) => {
    setDialogState({
      shouldOpen: true,
      type: "apiKey",
    });
    setKeyId(id);
    setAction("deactivate");
  };

  const openDeleteDialog = (id: number) => {
    setDialogState({
      shouldOpen: true,
      type: "apiKey",
    });
    setKeyId(id);
    setAction("delete");
  };

  useEffect(() => {
    if (apiKeysData) {
      const active = apiKeysData.find((key: ApiKeyData) => key.active);
      const deactivated = apiKeysData
        .filter((key: ApiKeyData) => !key.active)
        .sort((a: any, b: any) => b.id - a.id);
      setActiveKeys(active ? [active] : []);
      setDeactivatedKeys(deactivated);
    }
  }, [apiKeysData]);

  useEffect(() => {
    if (fetchingKeys || activeKeys.length) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [activeKeys.length, fetchingKeys]);

  return (
    <main className="relative pb-20 z-0 overflow-y-auto w-full">
      <div className="mt-8">
        {dialogState.shouldOpen && (
          <ApiKeyDialog action={action} keyId={keyId} />
        )}
        <div className="max-w-8xl mx-auto flex justify-between items-center pb-2">
          <div className="flex flex-col justify-start">
            <h2
              className="max-w-7xl px-4 text-3xl leading-6 font-bold text-gray-900 sm:px-6 lg:px-8 lg:pb-4"
              data-testid="api-key-header"
            >
              API Keys
            </h2>
            <div className="pl-8 my-6">
              <p
                className="mb-8 text-gray-900 text-base"
                data-testid="api-key-subhead"
              >
                Generate an API Key to securely access Reboot Motion resources.
              </p>
              <PrimaryButton
                className=""
                onClick={generateKeyAndOpenModal}
                disabled={disableBtn}
                data-testid="generate-button"
              >
                Generate API Key
              </PrimaryButton>
            </div>
          </div>
        </div>
        <div className="max-w-8xl mx-auto flex justify-between items-center pb-2 pt-8">
          <div className="flex justify-start items-end xl:items-center">
            <h3
              className="mt-4 px-4 text-2xl leading-6 font-bold text-gray-900 sm:px-6 lg:px-8 lg:pb-6"
              data-testid="active-key-header"
            >
              Active API Key
            </h3>
          </div>
        </div>
        <div
          className="block min-w-max"
          data-testid="active-key-table-container"
        >
          <Table
            columns={activeKeyTableColumns}
            data={activeKeyTableData}
            data-testid="active-key-table"
            selectedPageSize={"5"}
            displayFooter={false}
            fetching={false}
            customRowClassName="active-api-key-row"
            hideSearch={true}
            apiPage={true}
          />
        </div>
        {deactivatedKeys.length ? (
          <>
            <div className="max-w-8xl mx-auto flex justify-between items-center pb-2 pt-8">
              <div className="flex justify-start items-end xl:items-center">
                <h3
                  className="mt-4 px-4 text-2xl leading-6 font-bold text-gray-900 sm:px-6 lg:px-8 lg:pb-6"
                  data-testid="deactivated-keys-header"
                >
                  Deactivated API Key(s)
                </h3>
              </div>
            </div>

            <div
              className="block min-w-max"
              data-testid="deactivated-keys-table-container"
            >
              <Table
                columns={deactivatedKeyTableColumns}
                data={deactivatedKeyTableData}
                data-testid="deactivated-keys-table"
                selectedPageSize={"5"}
                displayFooter={deactivatedKeys.length > 5 ? true : false}
                fetching={false}
                customRowClassName="deactivated-api-key-row"
                hideSearch={true}
              />
            </div>
          </>
        ) : null}
      </div>
    </main>
  );
};
