import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Redirect, useParams } from "react-router-dom";

import { RegistrationForm } from "../../components";
import { ErrorToast } from "../../components/shared";
import { useCheckRegistrationTokenQuery } from "../../services/user";

export type RegistrationDetails = {
  email: string;
  orgId: string;
  id: number;
  registrationToken: string;
  sentAt: string;
  valid: boolean;
  validUntil: string;
};

type RegistrationRouteParams = {
  token: string;
};

export const Registration: React.FC = () => {
  const registrationRouteParams = useParams<RegistrationRouteParams>();
  const token = registrationRouteParams.token;

  const [userRegistrationDetails, setUserRegistrationDetails] =
    useState<RegistrationDetails>({
      email: "",
      id: 0,
      orgId: "",
      registrationToken: "",
      sentAt: "",
      valid: false,
      validUntil: "",
    });

  const {
    data: registrationData,
    isSuccess,
    isError,
    error,
  } = useCheckRegistrationTokenQuery(token);

  const errorToast = (message: string) =>
    toast.custom(<ErrorToast message={message} classNames="mt-modal" />, {
      id: "registrationError",
      duration: 6000,
    });

  useEffect(() => {
    if (error && "status" in error) {
      if (error.status === 404) {
        errorToast("This registration link is invalid.");
      } else if (error.status === 419) {
        errorToast(
          "This registration link has expired. Please check your email for a new link."
        );
      } else {
        errorToast("An error occurred. Please try again.");
      }
    }
  }, [error]);

  useEffect(() => {
    if (registrationData) {
      setUserRegistrationDetails(registrationData);
    }
  }, [registrationData]);

  return (
    <>
      {isSuccess && (
        <div
          className="min-h-screen min-w-full bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8"
          data-testid="registration-root-element"
        >
          <div
            className="sm:mx-auto sm:w-full sm:max-w-md bg-primary-800 sm:rounded-lg sm:shadow-lg py-4"
            data-testid="registration-header"
          >
            <img
              className="mx-auto h-12 w-auto"
              src="/RebootLogo.png"
              alt="Reboot Motion logo with the words Reboot and Motion stacked on top of each other with the top one bold and bottom one regular weight"
              data-testid="reboot-logo"
            />
            <h2
              className="mt-6 text-center text-3xl font-extrabold text-white"
              data-testid="registration-heading"
            >
              Create your account
            </h2>
          </div>
          <RegistrationForm
            email={userRegistrationDetails.email}
            orgId={userRegistrationDetails.orgId}
            token={userRegistrationDetails.registrationToken}
          />
        </div>
      )}
      {isError && <Redirect to="/login" />}
    </>
  );
};
