/** @module Components */

import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { addDays, format, parse, parseISO } from "date-fns";
import { Field, Formik, FormikProps } from "formik";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Tooltip } from "react-tooltip";
import * as Yup from "yup";

import { BlankButton, PrimaryButton, SpinnerButton } from "..";
import { useAppContext } from "../../../context";
import { useAppSelector } from "../../../redux/hooks";
import {
  useCreatePlayerMutation,
  useEditPlayerMutation,
} from "../../../services";
import {
  CustomCalendarIcon,
  emptyPlayerFormValues,
  regexForForeignLettersAndSpecialChars,
} from "../../../shared/Constants";
import { camelCaseResponse, classNames } from "../../../shared/Functions";
import { Player, PlayerFormValues } from "../../../types";
import { CustomDropdownWithChevron } from "../components/CustomSelectComponents";
import { ErrorToast, SuccessToast } from "../components/StyledToasts";

/** @category Prop Types*/
export type PlayerFormProps = {
  /** Form values to fill out the player form, whether it's being edited or created */
  formValues: PlayerFormValues;
  currentOrgPlayerId?: string;
};

export const PlayerForm: React.FC<PlayerFormProps> = ({
  formValues,
  currentOrgPlayerId,
}) => {
  // State and AppContext
  const {
    setModalState,
    modalTitle,
    modalState,
    setModalSingleStep,
    setPlayerIdToRetrieve,
    playerFormValues,
    setPlayerFormValues,
  } = useAppContext();
  const [leagueIdError, setLeagueIdError] = useState("");
  const [validOLID, setValidOLID] = useState(false);

  // Redux calls
  const orgId = useAppSelector((state) => state.org.id);
  const orgType = useAppSelector((state) => state.user.org.type);

  // RTK Mutations
  const [createPlayer, { isLoading: addingPlayer }] = useCreatePlayerMutation();
  const [editPlayer, { isLoading: editingPlayer }] = useEditPlayerMutation();

  // Toasts
  const toastSuccess = (message: string) =>
    toast.custom(
      <SuccessToast message={message} classNames="mt-modal w-1/3" />,
      {
        duration: 2000,
      }
    );
  const toastError = (message: string) =>
    toast.custom(<ErrorToast message={message} classNames={"mt-modal"} />, {
      id: "playerFormError",
    });

  const formRef = useRef<FormikProps<PlayerFormValues>>(null);

  const handednessOptions = [
    { value: "righty", label: "Righty" },
    { value: "lefty", label: "Lefty" },
    { value: "switch", label: "Switch" },
  ];

  useEffect(() => {
    if (!modalState && formRef.current) {
      formRef.current.resetForm();
    }
  }, [modalState]);

  const displayPlayerMatchWarning = (matchingPlayers: Player[]) => {
    return toast.custom(
      <div
        className="rounded-md bg-yellow-100 p-4 w-1/3"
        data-testid="duplicate-player-toast-container"
      >
        <div className="flex w-full">
          <div className="flex-shrink-0">
            <ExclamationTriangleIcon
              className="h-5 w-5 text-yellow-500"
              aria-hidden="true"
            />
          </div>
          <div
            className="ml-3 w-full"
            data-testid="duplicate-player-toast-message-container"
          >
            <p
              className="text-sm font-medium text-yellow-700 z-max pb-4"
              data-testid="player-toast-message"
            >
              It looks like this player already exists:
            </p>
            {matchingPlayers.map((player) => {
              return (
                <div
                  className="flex text-sm py-2 w-full"
                  data-testid="matching-player-container"
                  key={player.orgPlayerId}
                >
                  <div
                    className="flex font-medium text-gray-800 text-center"
                    data-testid="player-details"
                  >
                    <p className="pr-8" data-testid="player-name">
                      <span className="font-bold pr-1">Name:</span>{" "}
                      {player.firstName} {player.lastName}
                    </p>
                    <p className="pr-8" data-testid="birth-date">
                      <span className="font-bold pr-1">Birth Date:</span>{" "}
                      {format(parseISO(player.dateOfBirth), "M/d/yyyy")}
                    </p>
                    <Link
                      to={`/player/${player.orgPlayerId}`}
                      target="_blank"
                      className="text-blue-700 font-bold"
                      data-testid="matching-player-link"
                    >
                      View Player
                    </Link>
                  </div>
                </div>
              );
            })}
            <div className="flex w-full pt-4 pb-2" data-testid="toast-buttons">
              <PrimaryButton
                onClick={() => {
                  toast.remove();
                  setModalState(true);
                }}
                data-testid="cancel-toast-button"
              >
                Cancel
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>,
      {
        duration: Infinity,
      }
    );
  };

  const handleCheckboxClick = () => {
    if (formRef.current) {
      const { values } = formRef.current;
      if (values.orgPlayerId === values.officialLeagueId) {
        formRef.current.setFieldValue("orgPlayerId", "");
      } else {
        formRef.current.setFieldValue("orgPlayerId", values.officialLeagueId);
      }
    }
  };

  const getPlayerFromMLB = async (id: string) => {
    try {
      const response = await fetch(
        `https://statsapi.mlb.com/api/v1/people/${id}`
      );

      const result = await response.json();
      const playerInfo = result.people[0];

      const setHandedness = (code: string) => {
        if (code === "R") {
          return { value: "righty", label: "Righty" };
        } else if (code === "L") {
          return { value: "lefty", label: "Lefty" };
        } else {
          return { value: "switch", label: "Switch" };
        }
      };

      const handedThrows = setHandedness(playerInfo.pitchHand.code);
      const handedHits = setHandedness(playerInfo.batSide.code);
      const heightFeet = playerInfo.height.split("'")[0];
      const heightInches = playerInfo.height.split("' ")[1].split('"')[0];

      formRef.current?.setValues({
        ...formRef.current.values,
        firstName: playerInfo.firstName,
        middleName: playerInfo.middleName ? playerInfo.middleName : "",
        lastName: playerInfo.lastName,
        dateOfBirth: parse(playerInfo.birthDate, "yyyy-MM-dd", new Date()),
        throws: handedThrows,
        hits: handedHits,
        heightFeet:
          formRef.current?.values.heightFeet !== ""
            ? formRef.current?.values.heightFeet
            : heightFeet,
        heightInches:
          formRef.current?.values.heightInches !== ""
            ? formRef.current?.values.heightInches
            : heightInches,
        weight:
          formRef.current?.values.weight !== ""
            ? formRef.current?.values.weight
            : playerInfo.weight,
      });

      setValidOLID(true);
    } catch (error) {
      setLeagueIdError("MLBAM ID not found");
      setValidOLID(false);
    }
  };

  useEffect(() => {
    if (orgType === "MLB" && modalTitle.includes("Edit")) {
      if (
        formRef.current?.values.officialLeagueId !== "" &&
        formRef.current?.values.officialLeagueId === formValues.officialLeagueId
      ) {
        setValidOLID(true);
      } else {
        setValidOLID(false);
      }
    }
  }, [formValues, modalTitle, orgType]);

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={
        playerFormValues.firstName !== "" ? playerFormValues : formValues
      }
      validationSchema={Yup.object({
        firstName: Yup.string()
          .matches(
            regexForForeignLettersAndSpecialChars,
            "Please enter a valid name"
          )
          .required(""),
        middleName: Yup.string()
          .nullable()
          .matches(
            regexForForeignLettersAndSpecialChars,
            "Please enter a valid name"
          ),
        lastName: Yup.string()
          .matches(
            regexForForeignLettersAndSpecialChars,
            "Please enter a valid name"
          )
          .required(""),
        dateOfBirth: Yup.date().required(""),
        throws: Yup.object().shape({
          value: Yup.string().required(""),
        }),
        hits: Yup.object().shape({
          value: Yup.string().required(""),
        }),
        heightFeet: Yup.string()
          .matches(/^[0-9]+$/, "Must be a number")
          .required(""),
        heightInches: Yup.string()
          .matches(/^[0-9]+$/, "Must be a number")
          .required(""),
        weight: Yup.string()
          .matches(/^[0-9]+$/, "Must be a number")
          .required(""),
        orgPlayerId: Yup.string(),
        officialLeagueId: Yup.string().matches(
          /[0-9]{6,}/,
          "Must be a number at least 6 digits long"
        ),
      })}
      onSubmit={async (values, { resetForm }) => {
        setPlayerFormValues(values);
        const apiData = {
          formValues: values,
          orgId,
          orgPlayerId: currentOrgPlayerId,
        };
        if (modalTitle.includes("Add New Player")) {
          createPlayer(apiData)
            .unwrap()
            .then((response) => {
              setPlayerIdToRetrieve(response.orgPlayerId);
              setModalState(false);
              toastSuccess("Player Saved");
              resetForm();
              setModalSingleStep(<></>);
              setPlayerFormValues(emptyPlayerFormValues);
            })
            .catch((error) => {
              if (error.status === 409) {
                const response = camelCaseResponse(error.data);
                if (
                  response.detail.duplicates &&
                  response.detail.duplicates.length
                ) {
                  setModalState(false);
                  const duplicatePlayers = response.detail.duplicates;
                  displayPlayerMatchWarning(duplicatePlayers);
                } else
                  toastError(
                    "There was an issue saving the player. Please try again."
                  );
              } else {
                toastError(
                  "There was an issue saving the player. Please try again."
                );
              }
            });
        } else if (modalTitle.includes("Edit")) {
          editPlayer(apiData)
            .unwrap()
            .then((response) => {
              setModalState(false);
              setPlayerIdToRetrieve(response.orgPlayerId);
              toastSuccess("Player Saved");
              setPlayerFormValues(emptyPlayerFormValues);
            })
            .catch(() => {
              toastError(
                "There was an issue editing the player. Please try again."
              );
            });
        }
      }}
    >
      {({
        values,
        setFieldValue,
        handleReset,
        handleSubmit,
        setFieldTouched,
        touched,
        errors,
        dirty,
        isValid,
      }) => (
        <form
          className="flex flex-col h-full w-full"
          data-testid="player-form"
          onSubmit={handleSubmit}
        >
          <fieldset>
            {orgType === "MLB" && (
              <div
                className="bg-[#F9FAFB] h-28 w-full flex justify-center items-center"
                data-testid="player-ids-container"
              >
                <div className="w-1/2 flex justify-center items-center">
                  <div
                    data-testid="official-league-id-container"
                    className="w-2/3"
                  >
                    <div className="flex pb-1">
                      <label
                        htmlFor="officialLeagueId"
                        data-testid="official-league-id-label"
                        className="block text-sm font-medium text-gray-500"
                      >
                        Official League ID
                      </label>
                      <a data-testid="official-league-id-tooltip-icon">
                        <InformationCircleIcon
                          className="w-3 text-gray-900 ml-2"
                          data-tooltip-id="official-league-id-tooltip"
                          id="official-league-id-tooltip"
                        />
                      </a>
                      <Tooltip
                        id="official-league-id-tooltip"
                        data-testid="official-league-id-tooltip"
                        place="right"
                        style={{
                          background: "#FFFFFF",
                          borderRadius: "4px",
                          boxShadow:
                            "0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)",
                          width: "196px",
                          height: "48px",
                          padding: "12px",
                        }}
                      >
                        <p
                          data-testid="official-league-id-tooltip-text"
                          className="text-primary-800 text-sm text-center"
                        >
                          This is the MLBAM ID.
                        </p>
                      </Tooltip>
                    </div>
                    <Field
                      type="text"
                      id="officialLeagueId"
                      name="officialLeagueId"
                      value={values.officialLeagueId}
                      data-testid="official-league-id-input"
                      className={classNames(
                        touched.officialLeagueId && errors.officialLeagueId
                          ? "border-red-500 focus:ring-red-500 focus:border-red-500"
                          : "border-[#D1D5DB] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500",
                        "font-medium text-base w-full text-gray-900 block h-9 py-0.5 sm:text-sm rounded-md capitalize disabled:opacity-50 disabled:bg-gray-100 disabled:cursor-not-allowed"
                      )}
                      autoComplete="off"
                      data-1p-ignore
                      onBlur={() => {
                        setFieldTouched("officialLeagueId", true);
                        if (
                          values.officialLeagueId &&
                          values.officialLeagueId.length >= 6
                        ) {
                          getPlayerFromMLB(values.officialLeagueId);
                        }
                      }}
                      onKeyUp={() => {
                        if (formValues.officialLeagueId === "") {
                          setLeagueIdError("");
                          setValidOLID(false);
                        }
                      }}
                      disabled={
                        modalTitle.includes("Edit") &&
                        values.officialLeagueId !== "" &&
                        values.officialLeagueId === formValues.officialLeagueId
                      }
                    />
                    {touched.officialLeagueId && errors.officialLeagueId ? (
                      <div
                        className="font-medium text-xs text-red-500"
                        data-testid="olid-validation-error"
                      >
                        {errors.officialLeagueId}
                      </div>
                    ) : leagueIdError !== "" ? (
                      <div
                        className="font-medium text-xs text-red-500"
                        data-testid="olid-api-error"
                      >
                        {leagueIdError}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="w-1/2 flex justify-center">
                  <div data-testid="org-player-id-container" className="w-2/3">
                    <div className="flex justify-between items-center pb-1">
                      <label
                        htmlFor="orgPlayerId"
                        data-testid="org-player-id-label"
                        className="block text-sm font-medium text-gray-500"
                      >
                        Org Player ID
                      </label>
                      <div className="flex items-center flex-end">
                        <input
                          type="checkbox"
                          id="orgPlayerIdCheckbox"
                          className="border-gray-300 rounded-[4px] mr-2 checked:bg-[#FB6A07] checked:focus:bg-[#FB6A07] checked:hover:bg-[#FB6A07] focus:ring-[#FB6A07] focus:ring-opacity-50 focus:ring-2"
                          onChange={handleCheckboxClick}
                          data-testid="org-player-id-checkbox"
                          checked={
                            values.orgPlayerId !== "" &&
                            values.officialLeagueId !== "" &&
                            values.orgPlayerId === values.officialLeagueId
                          }
                        />
                        <label
                          htmlFor="orgPlayerIdCheckbox"
                          className="text-sm text-[#979797]"
                          data-testid="org-player-id-checkbox-label"
                        >
                          Same as League ID
                        </label>
                      </div>
                    </div>
                    <Field
                      type="text"
                      id="orgPlayerId"
                      name="orgPlayerId"
                      value={values.orgPlayerId}
                      data-testid="org-player-id-input"
                      className="font-medium text-base w-full text-gray-900 block h-9 py-0.5 sm:text-sm rounded-md capitalize border-[#D1D5DB]"
                      autoComplete="off"
                      data-1p-ignore
                    />
                  </div>
                </div>
              </div>
            )}

            <div data-testid="inputs-container" className="flex">
              <div
                data-testid="left-column-container"
                className="w-1/2 space-y-6 pt-5 pb-10 flex flex-col items-center"
              >
                <div data-testid="first-name-container" className="w-2/3">
                  <div className="flex w-full pb-1">
                    <label
                      htmlFor="firstName"
                      data-testid="first-name-label"
                      className="block text-sm font-medium text-gray-500"
                    >
                      First Name
                    </label>
                    <span
                      className="text-red-500 text-sm"
                      data-testid="first-name-required-asterisk"
                    >
                      *
                    </span>
                  </div>
                  <Field
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={values.firstName}
                    data-testid="first-name-input"
                    className={classNames(
                      (touched.firstName && errors.firstName) ||
                        (touched.firstName && errors.firstName === "")
                        ? "border-red-500 focus:ring-red-500 focus:border-red-500"
                        : "border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500",
                      "font-medium text-base w-full text-gray-900 block h-9 py-0.5 sm:text-sm rounded-md capitalize disabled:opacity-50 disabled:bg-gray-100 disabled:cursor-not-allowed"
                    )}
                    disabled={validOLID}
                    autoComplete="off"
                    data-1p-ignore
                  />
                  {touched.firstName && errors.firstName ? (
                    <div
                      className="font-medium text-xs text-red-500"
                      data-testid="first-name-error"
                    >
                      {errors.firstName}
                    </div>
                  ) : null}
                </div>

                <div data-testid="middle-name-container" className="w-2/3">
                  <div className="flex justify-between pb-1">
                    <label
                      htmlFor="middleName"
                      data-testid="middle-name-label"
                      className="block text-sm font-medium text-gray-500"
                    >
                      Middle Name
                    </label>
                  </div>
                  <Field
                    type="text"
                    id="middleName"
                    name="middleName"
                    value={values.middleName}
                    data-testid="middle-name-input"
                    className={classNames(
                      touched.middleName && errors.middleName
                        ? "border-red-500 focus:ring-red-500 focus:border-red-500"
                        : "border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500",
                      "font-medium text-base w-full text-gray-900 block h-9 py-0.5 sm:text-sm rounded-md capitalize disabled:opacity-50 disabled:bg-gray-100 disabled:cursor-not-allowed"
                    )}
                    disabled={validOLID}
                    autoComplete="off"
                    data-1p-ignore
                  />
                  {touched.middleName && errors.middleName ? (
                    <div
                      className="font-medium text-xs text-red-500"
                      data-testid="middle-name-error"
                    >
                      {errors.middleName}
                    </div>
                  ) : null}
                </div>

                <div data-testid="last-name-container" className="w-2/3">
                  <div className="flex pb-1">
                    <label
                      htmlFor="lastName"
                      data-testid="last-name-label"
                      className="block text-sm font-medium text-gray-500"
                    >
                      Last Name
                    </label>
                    <span
                      className="text-red-500 text-sm"
                      data-testid="first-name-required-asterisk"
                    >
                      *
                    </span>
                  </div>
                  <Field
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={values.lastName}
                    data-testid="last-name-input"
                    className={classNames(
                      (touched.lastName && errors.lastName === "") ||
                        (touched.lastName && errors.lastName)
                        ? "border-red-500 focus:ring-red-500 focus:border-red-500"
                        : "border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500",
                      "font-medium text-base w-full text-gray-900 block h-9 py-0.5 sm:text-sm rounded-md capitalize disabled:opacity-50 disabled:bg-gray-100 disabled:cursor-not-allowed"
                    )}
                    disabled={validOLID}
                    autoComplete="off"
                    data-1p-ignore
                  />
                  {touched.lastName && errors.lastName ? (
                    <div
                      className="font-medium text-xs text-red-500"
                      data-testid="last-name-error"
                    >
                      {errors.lastName}
                    </div>
                  ) : null}
                </div>

                <div data-testid="birth-date-container" className="w-2/3">
                  <div className="flex pb-1">
                    <label
                      htmlFor="dateOfBirth"
                      data-testid="birth-date-label"
                      className="block text-sm font-medium text-gray-500"
                    >
                      Birth Date
                    </label>
                    <span
                      className="text-red-500 text-sm"
                      data-testid="first-name-required-asterisk"
                    >
                      *
                    </span>
                  </div>
                  <div
                    className={classNames(
                      (touched.dateOfBirth && values.dateOfBirth === null) ||
                        (touched.dateOfBirth && errors.dateOfBirth)
                        ? "border-red-500 focus:ring-red-500 focus:border-red-500"
                        : "border-gray-300 focus-within:border-indigo-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500",
                      validOLID
                        ? "opacity-50 bg-gray-100 cursor-not-allowed"
                        : "",
                      "flex items-center w-full h-9 border rounded-md"
                    )}
                    data-testid="date-picker-container"
                  >
                    <DatePicker
                      selected={values.dateOfBirth}
                      id="dateOfBirth"
                      name="dateOfBirth"
                      showMonthDropdown
                      showYearDropdown
                      maxDate={addDays(new Date(), 0)}
                      dropdownMode="select"
                      dateFormat="M/d/yyyy"
                      data-testid="birth-date-picker"
                      className="font-medium text-base w-full text-gray-900 h-8 py-0.5 rounded-md border-none sm:text-sm cursor-default disabled:opacity-50 disabled:bg-gray-100 disabled:cursor-not-allowed"
                      onChange={(date) => {
                        if (date instanceof Date) {
                          setFieldValue("dateOfBirth", date);
                        }
                      }}
                      onBlur={() => {
                        setFieldTouched("dateOfBirth", true, true);
                      }}
                      disabled={validOLID}
                    />
                    <CustomCalendarIcon />
                  </div>
                </div>
              </div>

              <div
                data-testid="right-column-container"
                className="w-1/2 space-y-6 pt-5 pb-10 flex flex-col items-center"
              >
                <div data-testid="throws-container" className="w-2/3">
                  <div className="flex pb-1">
                    <label
                      id="throws-aria-label"
                      htmlFor="throws"
                      className="block text-sm font-medium text-gray-500"
                    >
                      Throws
                    </label>
                    <span
                      className="text-red-500 text-sm"
                      data-testid="first-name-required-asterisk"
                    >
                      *
                    </span>
                  </div>
                  <Select
                    classNamePrefix="throws-select"
                    name="throws"
                    aria-labelledby="throws-aria-label"
                    inputId="throws-select"
                    options={handednessOptions}
                    value={values.throws}
                    isSearchable={false}
                    onChange={(throws) => {
                      if (throws) {
                        setFieldValue("throws", throws);
                      }
                    }}
                    styles={{
                      control: (provided: any, { isDisabled }) => ({
                        ...provided,
                        fontSize: "0.875rem",
                        border: `${
                          errors.throws
                            ? touched.throws && errors.throws.value === ""
                              ? "1px solid rgb(239 68 68)"
                              : "1px solid #cccccc"
                            : "1px solid #cccccc"
                        }`,
                        boxShadow: "none",
                        borderColor: "#D1D5DB",
                        borderRadius: "6px",
                        cursor: isDisabled ? "not-allowed" : "default",
                        backgroundColor: isDisabled ? "#f3f4f6" : "white",
                        opacity: isDisabled ? "0.5" : "",
                      }),
                    }}
                    onBlur={() => {
                      setFieldTouched("throws", true);
                    }}
                    components={{
                      DropdownIndicator: CustomDropdownWithChevron,
                      IndicatorSeparator: () => null,
                    }}
                    isDisabled={validOLID}
                  />
                </div>

                <div data-testid="hits-container" className="w-2/3">
                  <div className="flex pb-1">
                    <label
                      id="hits-aria-label"
                      htmlFor="hits-select"
                      className="block text-sm font-medium text-gray-500"
                    >
                      Hits
                    </label>
                    <span
                      className="text-red-500 text-sm"
                      data-testid="first-name-required-asterisk"
                    >
                      *
                    </span>
                  </div>
                  <Select
                    classNamePrefix="hits-select"
                    name="hits"
                    aria-labelledby="hits-aria-label"
                    inputId="hits-select"
                    options={handednessOptions}
                    value={values.hits}
                    isSearchable={false}
                    onChange={(hits) => {
                      if (hits) {
                        setFieldValue("hits", hits);
                      }
                    }}
                    styles={{
                      control: (provided: any, { isDisabled }) => ({
                        ...provided,
                        fontSize: "0.875rem",
                        border: `${
                          errors.hits
                            ? touched.hits && errors.hits.value === ""
                              ? "1px solid rgb(239 68 68)"
                              : "1px solid #cccccc"
                            : "1px solid #cccccc"
                        }`,
                        boxShadow: "none",
                        borderColor: "#D1D5DB",
                        borderRadius: "6px",
                        cursor: isDisabled ? "not-allowed" : "default",
                        backgroundColor: isDisabled ? "#f3f4f6" : "white",
                        opacity: isDisabled ? "0.5" : "",
                      }),
                    }}
                    onBlur={() => {
                      setFieldTouched("hits", true);
                    }}
                    components={{
                      DropdownIndicator: CustomDropdownWithChevron,
                      IndicatorSeparator: () => null,
                    }}
                    isDisabled={validOLID}
                  />
                </div>

                <div data-testid="height-container" className="w-2/3">
                  <div className="flex pb-1">
                    <label
                      htmlFor="height"
                      data-testid="height-label"
                      className="block text-sm font-medium text-gray-500"
                    >
                      Height
                    </label>
                    <span
                      className="text-red-500 text-sm"
                      data-testid="first-name-required-asterisk"
                    >
                      *
                    </span>
                  </div>
                  <div
                    data-testid="height-input-container"
                    className="flex justify-between"
                  >
                    <div className="flex flex-col w-1/2 mr-2">
                      <div data-testid="height-feet-container" className="h-9">
                        <div
                          className={classNames(
                            (touched.heightFeet && errors.heightFeet === "") ||
                              (touched.heightInches &&
                                values.heightInches !== "" &&
                                values.heightFeet === "")
                              ? "border-red-500 focus:ring-red-500 focus:border-red-500"
                              : "",
                            "flex items-center w-full rounded-md border border-gray-300 focus-within:border-indigo-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                          )}
                          data-testid="height-feet-input-container"
                        >
                          <Field
                            type="text"
                            id="heightFeet"
                            name="heightFeet"
                            value={values.heightFeet}
                            data-testid="height-feet-input"
                            className="font-medium text-base w-full rounded-md text-gray-900 h-8 py-0.5 border-none sm:text-sm"
                          />
                          <span
                            className="pr-2 font-medium text-base text-secondary-800 text-right"
                            data-testid="feet-label"
                          >
                            ft
                          </span>
                        </div>
                        {touched.heightFeet && errors.heightFeet ? (
                          <div
                            className="font-medium text-xs text-red-500"
                            data-testid="height-feet-error"
                          >
                            {errors.heightFeet}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="flex flex-col w-1/2">
                      <div
                        data-testid="height-inches-container"
                        className="h-9"
                      >
                        <div
                          className={classNames(
                            (touched.heightInches &&
                              errors.heightInches === "") ||
                              ((touched.weight || values.weight !== "") &&
                                values.heightFeet !== "" &&
                                values.heightInches === "")
                              ? "border-red-500 focus:ring-red-500 focus:border-red-500"
                              : "",
                            "flex items-center w-full rounded-md border border-gray-300 focus-within:border-indigo-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                          )}
                          data-testid="height-inches-input-container"
                        >
                          <Field
                            type="text"
                            id="heightInches"
                            name="heightInches"
                            value={values.heightInches}
                            data-testid="height-inches-input"
                            className="font-medium text-base w-full rounded-md text-gray-900 h-8 py-0.5 border-none sm:text-sm"
                          />
                          <span
                            className="pr-2 font-medium text-base text-secondary-800 text-right"
                            data-testid="inches-label"
                          >
                            in
                          </span>
                        </div>
                        {touched.heightInches && errors.heightInches ? (
                          <div
                            className="font-medium text-xs text-red-500"
                            data-testid="height-inches-error"
                          >
                            {errors.heightInches}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div data-testid="weight-container" className="w-2/3">
                  <div className="flex pb-1">
                    <label
                      htmlFor="weight"
                      data-testid="weight-label"
                      className="block text-sm font-medium text-gray-500"
                    >
                      Weight
                    </label>
                    <span
                      className="text-red-500 text-sm"
                      data-testid="first-name-required-asterisk"
                    >
                      *
                    </span>
                  </div>
                  <div data-testid="weight-input-container" className="h-9">
                    <div
                      className={classNames(
                        (touched.weight && errors.weight) ||
                          (touched.weight && errors.weight === "")
                          ? "border-red-500 focus:ring-red-500 focus:border-red-500"
                          : "border-gray-300 focus-within:border-indigo-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500",
                        "flex items-center w-full border rounded-md"
                      )}
                      data-testid="weight-input-container"
                    >
                      <Field
                        type="text"
                        id="weight"
                        name="weight"
                        value={values.weight}
                        data-testid="weight-input"
                        className="font-medium text-base w-full text-gray-900 h-8 py-0.5 rounded-md border-none sm:text-sm"
                      />
                      <span
                        className="pr-2 font-medium text-base text-secondary-800 text-right"
                        data-testid="pounds-label"
                      >
                        lbs
                      </span>
                    </div>
                    {touched.weight && errors.weight ? (
                      <div
                        className="font-medium text-xs text-red-500"
                        data-testid="weight-error"
                      >
                        {errors.weight}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <footer
            data-testid="form-buttons-container"
            className="flex justify-end mb-5 pt-5 pr-5 w-full border-t border-gray-200"
          >
            <BlankButton
              data-testid="player-form-cancel-button"
              type="button"
              onClick={() => {
                setModalState(false);
                handleReset();
                setPlayerFormValues(emptyPlayerFormValues);
                setValidOLID(false);
                setLeagueIdError("");
              }}
            >
              Cancel
            </BlankButton>
            {addingPlayer || editingPlayer ? (
              <div className="ml-3">
                <SpinnerButton btnText={"Saving"} />
              </div>
            ) : (
              <PrimaryButton
                data-testid="save-player-button"
                type="submit"
                className="ml-3"
                disabled={!isValid || !dirty}
              >
                Save Player
              </PrimaryButton>
            )}
          </footer>
        </form>
      )}
    </Formik>
  );
};
