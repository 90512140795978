/** @module Components */
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { useDropzone } from "react-dropzone";
import { toast } from "react-hot-toast";

import { useAppContext } from "../../context";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { uploadSessionFormSlice } from "../../redux/slices/uploadSessionFormSlice";
import { ErrorToast } from "../shared";
import { SortableFileList } from "../SortableFileList/SortableFileList";

let counter = 0;

const acceptableFileExtensions = ["mov", "mp4"];

const error = (message: string) =>
  toast.custom(<ErrorToast message={message} classNames={"mt-modal"} />, {
    id: "uploadError",
  });

export const UploadFiles: React.FC = () => {
  // Redux calls
  const orgId = useAppSelector((state) => state.org.id);
  const uploadSessionFormValues = useAppSelector(
    (state) => state.uploadSessionForm
  );

  // RTK Slice actions
  const dispatch = useAppDispatch();
  const { storeUploadSessionFormState } = uploadSessionFormSlice.actions;

  // State and AppContext
  const { uploadFiles, setUploadFiles } = useAppContext();
  const [videoFps, setVideoFps] = useState(sessionStorage.getItem("videoFps"));

  // Can't remove the variable due to the useDropzone call
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop: (receivedAcceptedFiles: File[]) => {
      setUploadFiles([...uploadFiles, ...receivedAcceptedFiles]);
      const formValues = {
        ...uploadSessionFormValues,
        files: uploadSessionFormValues.files.concat(
          receivedAcceptedFiles.map((file) => {
            return {
              fileId: (counter += 1),
              fileSizeBytes: file.size,
              rawFileName: file.name,
              status: "Processing",
              frameRate: 0,
            };
          })
        ),
        orgId: orgId,
      };
      dispatch(storeUploadSessionFormState(formValues));
    },
    validator: fileValidator,
  });

  function fileValidator(newFile: File) {
    const fileExtension = newFile.name.split(".").pop();
    if (
      newFile.name !== undefined &&
      fileExtension &&
      !acceptableFileExtensions.includes(fileExtension.toLowerCase())
    ) {
      error(
        "Only certain video file formats are accepted at the moment: .mp4 or .mov"
      );
      return {
        code: "unacceptable-file-extension",
        message:
          "Only certain video file formats are accepted at the moment: .mp4 or .mov",
      };
    }
    const duplicateFiles = uploadSessionFormValues.files.filter(
      (file) => file.rawFileName === newFile.name
    );
    if (duplicateFiles.length > 0) {
      error("File is already queued for upload.");
      return {
        code: "duplicate-file",
        message: "File is already queued for upload.",
      };
    }
    return null;
  }

  return (
    <div
      className="flex w-full h-full items-start sm:justify-center sm:space-x-12 sm:mx-8 sm:my-8 pb-8 overflow-scroll"
      data-testid="dropzone-and-file-list-container"
    >
      <section className="w-full flex flex-col justify-center lg:flex-row-reverse">
        <div
          className="flex flex-col justify-center w-11/12 lg:w-1/2 mx-4"
          id="dropzone-container"
          data-testid="dropzone-container"
        >
          <div
            className="flex flex-col sm:flex-row mb-8 mt-4 sm:mt-8 w-full mx-auto sm:justify-center sm:mx-auto sm:items-center"
            data-testid="frame-rate-container"
          >
            <label
              id="frame-rate-label"
              htmlFor="frame-rate-input"
              className="block text-sm font-medium text-gray-500 pb-1 mr-4 place-items-center"
              data-testid="frame-rate-label"
            >
              Frame Rate
            </label>
            <input
              id="frame-rate-input"
              type="number"
              min={30}
              max={1000}
              required
              className="sm:w-1/2 sm:p-4 font-medium text-base text-gray-900 h-9 py-0.5 text-[12px] sm:text-sm rounded-md capitalize placeholder-gray-400 border-2 border-gray-300"
              placeholder="Video Frame Rate"
              data-testid="frame-rate-input"
              value={videoFps ? videoFps : ""}
              onChange={(event) => {
                sessionStorage.setItem("videoFps", event.target.value);
                setVideoFps(event.target.value);
              }}
            />
          </div>
          <div
            {...getRootProps({
              style: {
                cursor: "pointer",
              },
            })}
            data-testid="react-dropzone-container"
          >
            <input {...getInputProps()} data-testid="react-dropzone-input" />
            <div className="flex flex-col justify-center items-center pb-6">
              <ArrowUpTrayIcon
                className="text-secondary-800 h-24 w-24 sm:h-28 sm:w-28 border rounded-full bg-white border-gray-300 p-6"
                data-testid="upload-file-icon"
              />
              <div className="text-gray-700 pt-6">
                <p
                  className="font-bold text-base sm:text-xl text-center"
                  data-testid="upload-files-helper-text"
                >
                  {isMobile
                    ? "Click to select files to upload"
                    : "Click to select files or drag and drop..."}
                </p>
                <ul
                  className="mt-6 px-4 text-[12px] sm:text-sm list-disc sm:ml-10"
                  data-testid="upload-tips-list"
                >
                  <li data-testid="tip-1">
                    Only MP4 and MOV formats are currently supported
                  </li>
                  <li data-testid="tip-2">
                    Upload videos in the order that they were taken
                  </li>
                  <li data-testid="tip-3">
                    Submit more than 1, ideally 3 or more videos
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {uploadSessionFormValues.files &&
          uploadSessionFormValues.files.length > 0 && (
            <div
              className="sm:flex flex-col items-center lg:items-start w-11/12 lg:w-1/2 md:mt-6 overflow-scroll"
              data-testid="file-list-container"
            >
              <div className="w-full md:w-3/4 lg:w-full">
                <SortableFileList />
              </div>
            </div>
          )}
      </section>
    </div>
  );
};
