import { Field, Formik } from "formik";
import React from "react";
import { toast } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import { useRequestPasswordResetMutation } from "../../services";
import {
  BlankButton,
  ErrorToast,
  PrimaryButton,
  SuccessToast,
} from "../shared";

export const ForgotPasswordForm: React.FC = () => {
  const { push } = useHistory();

  const [requestPasswordReset, isSuccess] = useRequestPasswordResetMutation();

  const success = (message: string) =>
    toast.custom(<SuccessToast message={message} classNames="mt-modal" />, {
      duration: Infinity,
    });

  const error = (message: string) =>
    toast.custom(<ErrorToast message={message} classNames="mt-modal" />, {
      id: "forgotPasswordError",
      duration: 4000,
    });

  return (
    <Formik
      enableReinitialize
      initialValues={{
        username: "",
      }}
      validateOnChange={false}
      validationSchema={Yup.object({
        username: Yup.string()
          .email("Must be a valid email")
          .required("Required"),
      })}
      onSubmit={(values, isValid) => {
        if (isValid) {
          const data = {
            username: values.username,
          };
          requestPasswordReset(data)
            .unwrap()
            .then(() => {
              if (isSuccess) {
                success(
                  "If an account exists for this email, a reset password link has been sent."
                );
              }
            })
            .catch(() => {
              error(
                "There was an unspecified error when attempting to reset your password. Please try again later or contact support@rebootmotion.com if the error persists."
              );
            });
        }
      }}
    >
      {({ values, handleSubmit, touched, errors }) => (
        <form
          className="mt-2 sm:mx-auto sm:w-full sm:max-w-md"
          data-testid="forgot-form-component"
          onSubmit={handleSubmit}
        >
          <fieldset className="bg-white pt-8 pb-4 px-4 shadow sm:rounded-lg sm:shadow-lg sm:px-10">
            <h3
              className="pb-8 text-gray-700"
              data-testid="forgot-form-heading"
            >
              No problem. We&apos;ll send you an email to reset your password.
            </h3>
            <div data-testid="email-container">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700"
                data-testid="email-label"
              >
                Email address
              </label>
              <div className="mt-1">
                <Field
                  id="username"
                  name="username"
                  type="email"
                  autoComplete="email"
                  value={values.username}
                  className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
                    touched.username && errors.username
                      ? `border-red-600 focus:ring-red-600 focus:border-red-600`
                      : ""
                  }`}
                  data-testid="email-input"
                />
                {touched.username && errors.username ? (
                  <div
                    className="font-medium text-xs text-red-500 pt-1"
                    data-testid="email-error"
                  >
                    {errors.username}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="flex flex-col" data-testid="button-container">
              <PrimaryButton
                className="w-full flex justify-center py-2 px-4 mt-4"
                data-testid="reset-button"
                type="submit"
              >
                Reset
              </PrimaryButton>
              <BlankButton
                className="mt-3 border-none shadow-none"
                data-testid="login-button"
                onClick={() => push("/login")}
              >
                Back to login
              </BlankButton>
            </div>
          </fieldset>
        </form>
      )}
    </Formik>
  );
};
