import { XMarkIcon } from "@heroicons/react/24/outline";
import { Column } from "@tanstack/react-table";
import { addDays } from "date-fns";
import React from "react";
import DatePicker from "react-datepicker";

import { CustomCalendarIcon, years } from "../../../shared/Constants";
import { classNames } from "../../../shared/Functions";
import { CustomDatePickerHeader } from "./CustomDatePickerHeader";

type DateRangePickerProps = {
  placeholderText: string;
  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  segmentType?: string;
  onBlur?: () => void;
  column?: Column<Record<string, unknown>, unknown> | undefined;
  setDateFilter?: React.Dispatch<React.SetStateAction<string>>;
};

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  placeholderText,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  segmentType,
  onBlur,
  column,
  setDateFilter,
}: DateRangePickerProps) => {
  const handleCustomDateRange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    column?.setFilterValue(dates);
  };

  return (
    <div className="flex items-center h-[38px] border border-[#DBDEE2] rounded-md">
      <DatePicker
        renderCustomHeader={({
          date,
          monthDate,
          customHeaderCount,
          decreaseMonth,
          increaseMonth,
          changeYear,
          nextMonthButtonDisabled,
        }) => (
          <CustomDatePickerHeader
            date={date}
            monthDate={monthDate}
            customHeaderCount={customHeaderCount}
            decreaseMonth={decreaseMonth}
            increaseMonth={increaseMonth}
            changeYear={changeYear}
            nextMonthButtonDisabled={nextMonthButtonDisabled}
            yearsDropdown={years}
            segmentType={segmentType}
            isMulti={false}
          />
        )}
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        monthsShown={2}
        id="custom-date-range"
        name="custom-date-range"
        shouldCloseOnSelect={false}
        placeholderText={placeholderText}
        maxDate={addDays(new Date(), 0)}
        dateFormat="M/d/yyyy"
        className="font-medium text-base w-full text-gray-900 h-8 py-0.5 rounded-md border-none sm:text-sm cursor-default"
        onChange={(dateRange) => {
          handleCustomDateRange(dateRange);
        }}
        onBlur={onBlur}
      />
      <XMarkIcon
        className={classNames(
          !startDate
            ? "hidden"
            : "h-5 pr-2 mr-2.5 text-gray-400 cursor-pointer border-r border-[#D1D5DB] hover:text-gray-600"
        )}
        data-testid="clear-date-range-icon"
        onClick={() => {
          setStartDate(null);
          setEndDate(null);
          column?.setFilterValue("");
          setDateFilter && setDateFilter("");
        }}
      />
      <CustomCalendarIcon />
    </div>
  );
};
