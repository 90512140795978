import { getYear } from "date-fns";
import React from "react";

import { years } from "../../../shared/Constants";

type CustomDatePickerHeaderProps = {
  date: Date;
  changeYear: (year: number) => void;
  customHeaderCount: number;
  yearsDropdown: number[];
  decreaseMonth: () => void;
  increaseMonth: () => void;
  monthDate: Date;
  nextMonthButtonDisabled: boolean;
  isMulti: boolean;
  segmentType?: string;
};

export const CustomDatePickerHeader: React.FC<CustomDatePickerHeaderProps> = ({
  date,
  changeYear,
  customHeaderCount,
  segmentType,
  decreaseMonth,
  increaseMonth,
  monthDate,
  nextMonthButtonDisabled,
  isMulti,
}: CustomDatePickerHeaderProps) => {
  return (
    <div data-testid="custom-datepicker-header">
      <div
        data-testid="year-dropdown-container"
        className="flex justify-start border-b border-[#EEEDF0] mb-4"
      >
        <select
          value={getYear(date)}
          onChange={({ target: { value } }) => changeYear(Number(value))}
          className="text-gray-900 h-10 py-0.5 shadow-sm rounded-md border-gray-300 sm:text-sm cursor-default mb-4 ml-4"
          style={customHeaderCount === 1 ? { visibility: "hidden" } : undefined}
          data-testid="year-dropdown-select"
        >
          {years.map((option: any) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      <div
        className="flex justify-between bg-white"
        data-testid="custom-month-header"
      >
        <button
          aria-label="Previous Month"
          className="pl-6"
          style={customHeaderCount === 1 ? { visibility: "hidden" } : undefined}
          data-testid={
            segmentType
              ? `${segmentType}-previous-month-button`
              : "previous-month-button"
          }
          onClick={(event) => {
            event.preventDefault();
            decreaseMonth();
          }}
        >
          {"<"}
        </button>
        <span
          className="text-gray-900 font-medium text-sm justify-center items-center place-items-center"
          data-testid="month-name"
        >
          {monthDate.toLocaleString("en-US", {
            month: "long",
          })}
        </span>
        <button
          aria-label="Next Month"
          className="pr-6 disabled:opacity-50"
          style={
            (isMulti && nextMonthButtonDisabled) ||
            (!isMulti && customHeaderCount === 0)
              ? { visibility: "hidden" }
              : undefined
          }
          data-testid={
            segmentType
              ? `${segmentType}-next-month-button`
              : "next-month-button"
          }
          onClick={(event) => {
            event.preventDefault();
            increaseMonth();
          }}
          disabled={nextMonthButtonDisabled}
        >
          {">"}
        </button>
      </div>
    </div>
  );
};
