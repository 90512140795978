import React, { PropsWithChildren } from "react";
import { isBrowser, isMobile } from "react-device-detect";
import ReactModal from "react-modal";

import { ModalHeader } from "../../components/ModalHeader/ModalHeader";
import { mobileModalStyles } from "../../styles/modalStyles";

/** @category Prop Types */
export type ModalProps = {
  shouldShowModal: boolean;
  closeModal: () => void;
  title: string;
};

export const CustomModal: React.FC<PropsWithChildren<ModalProps>> = ({
  shouldShowModal,
  closeModal,
  title,
  children,
}: PropsWithChildren<ModalProps>) => {
  const screenWidth = window.innerWidth;

  ReactModal.setAppElement("#root");

  ReactModal.defaultStyles.overlay = {
    position: "fixed",
    zIndex: "9999",
    height: "100vh",
    width: "100vw",
    overflow: "auto",
    top: "0px",
    left: "0px",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    display: "block",
    transitionProperty: "all",
    transitionTimingFunction: "ease-in-out",
    transitionDuration: "0.4s",
    paddingTop: "45px",
    opacity: "1",
    marginBottom: "45px",
    border: "none",
  };

  ReactModal.defaultStyles.content = {
    fontFamily: "Futura",
    border: "none",
    borderRadius: "0.5rem",
    padding: "0",
    margin: "10vh auto 200px",
    height: "fit-content",
    width: "98%",
    maxWidth: "80vw",
    // default React Modal styles are added back here
    position: "absolute",
    top: "40px",
    left: "40px",
    right: "40px",
    bottom: "40px",
    background: "#fff",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    outline: "none",
  };

  return (
    <ReactModal
      isOpen={shouldShowModal}
      style={
        isMobile || (isBrowser && screenWidth < 1024) ? mobileModalStyles : {}
      }
    >
      <ModalHeader title={title} handleClose={closeModal} component="custom" />
      <div data-testid="custom-modal-body">{children}</div>
    </ReactModal>
  );
};
