import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";

import { ResetPasswordForm } from "../../components";
import { useCheckResetTokenQuery } from "../../services/user";

type ResetPasswordRouteParams = {
  token: string;
};

export const ResetPassword: React.FC = () => {
  const registrationRouteParams = useParams<ResetPasswordRouteParams>();
  const token = registrationRouteParams.token;

  const [username, setUsername] = useState("");

  const {
    data: resetPasswordData,
    isSuccess,
    isError,
  } = useCheckResetTokenQuery(token);

  useEffect(() => {
    if (resetPasswordData) {
      setUsername(resetPasswordData.email);
    }
  }, [resetPasswordData]);

  return (
    <>
      {isSuccess && (
        <div
          className="min-h-screen min-w-full bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8"
          data-testid="reset-root-element"
        >
          <div
            className="sm:mx-auto sm:w-full sm:max-w-md bg-primary-800 sm:rounded-lg sm:shadow-lg py-4"
            data-testid="reset-header"
          >
            <img
              className="mx-auto h-12 w-auto"
              src="/RebootLogo.png"
              alt="Reboot Motion logo with the words Reboot and Motion stacked on top of each other with the top one bold and bottom one regular weight"
              data-testid="reboot-logo"
            />
            <h2
              className="mt-6 text-center text-3xl font-extrabold text-white"
              data-testid="reset-heading"
            >
              Reset Password
            </h2>
          </div>
          <ResetPasswordForm email={username} token={token} />
        </div>
      )}
      {isError && <Redirect to="/error/403" />}
    </>
  );
};
