import React from "react";
import { classNames } from "../../shared/Functions";

type GradientProgressSpinnerProps = {
  progress: number | null;
};

export const GradientProgressSpinner: React.FC<
  GradientProgressSpinnerProps
> = ({ progress }: GradientProgressSpinnerProps): JSX.Element => {
  return (
    <div
      className="flex h-[36px] w-[36px]"
      data-testid="gradient-spinner-container"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 200 200"
        fill="none"
        color="#FB6A07"
        id="gradient-spinner"
      >
        <defs>
          <linearGradient id="spinner-secondHalf">
            <stop offset="0%" stopOpacity="0" stopColor="currentColor" />
            <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
          </linearGradient>
          <linearGradient id="spinner-firstHalf">
            <stop offset="0%" stopOpacity="1" stopColor="currentColor" />
            <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
          </linearGradient>
        </defs>

        <g strokeWidth="10">
          <path
            stroke="url(#spinner-secondHalf)"
            d="M 4 100 A 96 96 0 0 1 196 100"
          />
          <path
            stroke="url(#spinner-firstHalf)"
            d="M 196 100 A 96 96 0 0 1 4 100"
          />
          <path
            stroke="currentColor"
            strokeLinecap="round"
            d="M 4 100 A 96 96 0 0 1 4 98"
          />
        </g>
        <animateTransform
          from="0 0 0"
          to="360 0 0"
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="2000ms"
        />
      </svg>
      <p
        className={classNames(
          progress === 0
            ? "ml-[11px]"
            : progress === 100
            ? "ml-[3px]"
            : "ml-[7px]",
          "font-bold text-[10px] flex place-self-center absolute"
        )}
        id="gradient-spinner-text"
        data-testid="progress-percentage"
      >
        {progress !== null ? `${progress}%` : ""}
      </p>
    </div>
  );
};
