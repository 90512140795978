import { createSlice } from "@reduxjs/toolkit";

import { Org } from "../../types";
import { RootState } from "../store";

const initialState: Org = {
  id: "",
  name: "",
  type: "",
  externalId: "",
  details: {
    movementTypes: [],
    sessionTypes: [],
    sessionVenues: [],
    mocapTypes: [],
  },
  players: {
    total: 0,
  },
  sessions: {
    total: 0,
    pending: 0,
    complete: 0,
  },
};

export const orgSlice = createSlice({
  name: "org",
  initialState,
  reducers: {
    setOrgInformation: (state, action) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.type = action.payload.type;
      state.externalId = action.payload.externalId;
      state.details.mocapTypes = action.payload.orgMocapTypes;
      state.players = action.payload.players;
      state.sessions = action.payload.sessions;
    },
    updateOrgInformation: (state, action) => {
      if (action.payload.sessionTypes) {
        state.details.sessionTypes = action.payload.sessionTypes;
      } else if (action.payload.movementTypes) {
        state.details.movementTypes = action.payload.movementTypes;
      } else if (action.payload.players) {
        state.players = action.payload.players;
      } else if (action.payload.sessions) {
        state.sessions = action.payload.sessions;
      }
    },
  },
});

export const selectOrg = (state: RootState): Org => state.org;
