/** @module Components */

import {
  ArrowSmallDownIcon,
  ArrowSmallUpIcon,
  ArrowsUpDownIcon,
} from "@heroicons/react/24/outline";
import { flexRender, HeaderGroup } from "@tanstack/react-table";
import React from "react";
import tw from "tailwind-styled-components";

/** @category Prop Types */
export type HeaderProps = {
  headerGroups: HeaderGroup<Record<string, unknown>>[];
  headerRowClassName?: string;
  headerCellClassName?: string;
};

const StyledHeaderRow = tw.tr`
  bg-gray-50
  w-full
  border-b
  border-gray-200
`;

const StyledHeaderCell = tw.th`
  py-3
  text-left
  text-xs
  2xl:text-sm
  font-bold
  text-gray-500
  uppercase
  tracking-wider
`;

export const TableHeader = ({
  headerGroups,
  headerRowClassName,
  headerCellClassName,
}: HeaderProps): JSX.Element => {
  return (
    <thead>
      {headerGroups.map((headergroup) => (
        <StyledHeaderRow className={headerRowClassName} key={headergroup.id}>
          {headergroup.headers.map((header, index) => {
            return (
              <StyledHeaderCell
                key={header.id}
                colSpan={header.colSpan}
                className={
                  headerCellClassName +
                  `${header.column.id === "checkbox" ? "w-10" : "w-1/6"} + ${
                    index === 0 ? "pl-6" : ""
                  }` +
                  `${index === headergroup.headers.length - 1 ? "pr-6" : ""}`
                }
                data-testid={
                  header.column.getCanSort()
                    ? "sortable-column-header"
                    : "column-header"
                }
              >
                <div className="flex">
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                  {header.column.getCanSort() &&
                  !header.column.getIsSorted() ? (
                    <ArrowsUpDownIcon
                      className="h-4 2xl:h-5 pl-1 pr-4 sort-icon"
                      data-testid="sort-both-icon"
                      onClick={header.column.getToggleSortingHandler()}
                    />
                  ) : header.column.getIsSorted() === "asc" ? (
                    <ArrowSmallDownIcon
                      className="h-4 2xl:h-5 pl-1 pr-4 sort-icon"
                      data-testid="sort-down-icon"
                      onClick={header.column.getToggleSortingHandler()}
                    />
                  ) : header.column.getIsSorted() === "desc" ? (
                    <ArrowSmallUpIcon
                      className="h-4 2xl:h-5 pl-1 pr-4 sort-icon"
                      data-testid="sort-up-icon"
                      onClick={() => {
                        header.column.clearSorting();
                      }}
                    />
                  ) : null}
                </div>
              </StyledHeaderCell>
            );
          })}
        </StyledHeaderRow>
      ))}
    </thead>
  );
};
