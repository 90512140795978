/** @module SharedConstants */

import {
  CalendarIcon,
  DocumentChartBarIcon,
} from "@heroicons/react/24/outline";
import { format, getYear, subDays } from "date-fns";
import range from "lodash/range";
import React from "react";
import { toast } from "react-hot-toast";

import {
  SelectPlayerUpload,
  Step,
  StepProps,
  StepType,
  UploadFiles,
  UploadSpinner,
} from "../components";
import {
  AnalysisButton,
  ErrorToast,
  SessionUploadForm,
} from "../components/shared";
import {
  Org,
  Player,
  PlayerFormValues,
  ReportType,
  Session,
  UploadSessionFormValues,
} from "../types";
import { FormTypeEnum } from "../types/formTypes";

/** @category Prop Types */
export type UploadSessionStepListProps = {
  /** List of players, used in the first screen for the player select */
  playerList: Player[];
  /** The form values for the session upload process */
  sessionFormValues: UploadSessionFormValues;
  /** Organization data used on the second screen of the upload session form process */
  orgData: Org;
};

/** @category Prop Types */
export type InitialSessionUploadFormValuesProps = {
  /** Organization ID for the currently logged in user */
  orgId: string;
};

export const CustomCalendarIcon = (): JSX.Element => {
  return (
    <CalendarIcon
      className="h-6 pr-2 text-gray-700 stroke-2"
      data-testid="calendar-icon"
    />
  );
};

// Sourced from this Stack Overflow thread: https://stackoverflow.com/questions/3009993/what-would-be-regex-for-matching-foreign-characters
export const regexForForeignLettersAndSpecialChars = /^[\p{L}'\-\s\.]*$/u;

export const currentYear = format(new Date(), "yyyy");
export const today = format(new Date(), "M/d/yyyy");
export const years = range(1990, getYear(new Date()) + 1, 1);

export const dateRangeOptions = [
  { value: today, label: "Today" },
  { value: format(subDays(new Date(), 1), "M/d/yyyy"), label: "Yesterday" },
  {
    value: `${format(subDays(new Date(), 7), "M/d/yyyy")} - ${today}`,
    label: "Last 7 Days",
  },
  {
    value: `${format(subDays(new Date(), 14), "M/d/yyyy")} - ${today}`,
    label: "Last 14 Days",
  },
  {
    value: `${format(subDays(new Date(), 30), "M/d/yyyy")} - ${today}`,
    label: "Last 30 Days",
  },
  {
    value: `${format(subDays(new Date(), 60), "M/d/yyyy")} - ${today}`,
    label: "Last 60 Days",
  },
  {
    value: `${format(subDays(new Date(), 365), "M/d/yyyy")} - ${today}`,
    label: "Last Year",
  },
  { value: "Custom Range", label: "Custom Range" },
  { value: "Individual Dates", label: "Individual Dates" },
];

export const playerListLimit = 2000;
export const sessionListLimit = 1000;

export const emptyPlayer: Player = {
  id: "",
  firstName: "",
  middleName: "",
  lastName: "",
  dateOfBirth: "",
  throws: "",
  hits: "",
  height: {
    feet: "",
    inches: "",
  },
  weight: "",
  orgPlayerId: "",
  officialLeagueId: "",
};

export const emptyPlayerFormValues: PlayerFormValues = {
  firstName: "",
  middleName: "",
  lastName: "",
  dateOfBirth: null,
  throws: { value: "", label: "" },
  hits: { value: "", label: "" },
  heightFeet: "",
  heightInches: "",
  weight: "",
  orgPlayerId: "",
  officialLeagueId: "",
};

export const initialSessionFormValues = ({
  orgId,
}: InitialSessionUploadFormValuesProps): UploadSessionFormValues => {
  return {
    sessionDate: "",
    selectedPlayer: { value: "", label: "Select Player" },
    sessionType: { value: "", label: "Select Session Type" },
    sessionVenue: { value: "", label: "Select Venue" },
    movementType: { value: "", label: "Select Movement Type" },
    movementSubType: { value: "", label: "" },
    movementTags: [],
    files: [],
    orgId: orgId,
    firstDrop: true,
  };
};

export const uploadSessionStepList = ({
  playerList,
  sessionFormValues,
  orgData,
}: UploadSessionStepListProps): StepType[] => {
  return [
    {
      title: `Select Player`,
      display: <SelectPlayerUpload players={playerList ? playerList : []} />,
      disableNext: sessionFormValues.selectedPlayer.value === "",
      formType: FormTypeEnum.UploadSessionForm,
      element: (stepProps: StepProps) => <Step {...stepProps} />,
    },
    {
      title: "Details",
      display: <SessionUploadForm orgData={orgData} />,
      disableNext:
        sessionFormValues.sessionType.value === "" ||
        sessionFormValues.movementType.value === "" ||
        sessionFormValues.sessionVenue.value === "",
      formType: FormTypeEnum.UploadSessionForm,
      element: (stepProps: StepProps) => <Step {...stepProps} />,
    },
    {
      title: "Upload",
      display: <UploadFiles />,
      disableNext: sessionFormValues.files.length === 0,
      formType: FormTypeEnum.UploadSessionForm,
      element: (stepProps: StepProps) => <Step {...stepProps} />,
    },
  ];
};

export const errorToast = (message: string, id?: string): string =>
  toast.custom(<ErrorToast message={message} classNames={"mt-modal"} />, {
    id: id ? id : "",
  });

export const customSelectStyles = {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  control: (provided: any) => ({
    ...provided,
    fontSize: "0.875rem",
    borderColor: "#DBDEE2",
    borderRadius: "6px",
  }),
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  option: (styles: any, { isFocused, isSelected }: any) => {
    return {
      ...styles,
      backgroundColor: isSelected
        ? "#F9FAFB"
        : isFocused
        ? "#F9FAFB"
        : undefined,
      color: isSelected ? "#FB6A07" : undefined,
      ":active": {
        ...styles[":active"],
        backgroundColor: isSelected ? "#F9FAFB" : undefined,
      },
    };
  },
};

export const AnalysisCell = ({
  element,
  fetchingReports,
  session,
  openReportsModal,
}: {
  element: any;
  fetchingReports: boolean;
  session: Session | undefined;
  openReportsModal: (player: any, session: any) => void;
}): JSX.Element => {
  const urls = element.row.original.reports
    ? element.row.original.reports.map((report: ReportType) => report.reportUrl)
    : [];
  return (
    <div data-testid="analysis-cell">
      {fetchingReports && !urls.length ? (
        <div data-testid="upload-spinner-container">
          <UploadSpinner />
        </div>
      ) : urls.length ? (
        urls.length > 1 ? (
          <AnalysisButton
            data-testid="analysis-button"
            onClick={() => openReportsModal(element.row.original, session)}
          >
            <span className="flex">
              <DocumentChartBarIcon className="w-5 mr-2 stroke-2" /> (
              {urls.length})
            </span>
          </AnalysisButton>
        ) : (
          <a
            href={urls[0]}
            target="_blank"
            rel="noreferrer"
            data-testid="single-analysis-link"
          >
            <AnalysisButton data-testid="analysis-button">
              <DocumentChartBarIcon className="w-5 stroke-2" />
            </AnalysisButton>
          </a>
        )
      ) : (
        <AnalysisButton disabled data-testid="disabled-analysis-button">
          <DocumentChartBarIcon className="w-5 stroke-2" />
        </AnalysisButton>
      )}
    </div>
  );
};
