import { createContext, Dispatch, SetStateAction, useContext } from "react";

import { StepType } from "./components";
import { emptyPlayerFormValues } from "./shared/Constants";
import { PlayerFormValues } from "./types";
import { DialogStateType, PopupStateType } from "./types/sharedTypes";
import { AbortControllerInfo, UploadFile } from "./types/uploadSessionTypes";

interface AppContextInterface {
  setPopupTitle: Dispatch<SetStateAction<string>>;
  setPopupState: Dispatch<SetStateAction<boolean>>;
  popupState: boolean;
  popupTitle: string;
  exportPopupState: PopupStateType;
  setExportPopupState: Dispatch<SetStateAction<PopupStateType>>;
  modalState: boolean;
  setModalState: Dispatch<SetStateAction<boolean>>;
  modalTitle: string;
  setModalTitle: Dispatch<SetStateAction<string>>;
  setDisplayBack: Dispatch<SetStateAction<boolean>>;
  setModalStepList: Dispatch<SetStateAction<StepType[]>>;
  setModalSingleStep: Dispatch<SetStateAction<JSX.Element | undefined>>;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  currentStep: number;
  modalStepList: StepType[];
  uploadFiles: UploadFile[];
  setUploadFiles: Dispatch<SetStateAction<UploadFile[]>>;
  abortControllers: AbortControllerInfo[];
  setAbortControllers: Dispatch<SetStateAction<AbortControllerInfo[]>>;
  dialogState: {
    shouldOpen: boolean;
    type: string;
  };
  setDialogState: Dispatch<SetStateAction<DialogStateType>>;
  playerIdToRetrieve: string;
  setPlayerIdToRetrieve: Dispatch<SetStateAction<string>>;
  uploadInProgress: boolean;
  setUploadInProgress: Dispatch<SetStateAction<boolean>>;
  exportInProgress: boolean;
  setExportInProgress: Dispatch<SetStateAction<boolean>>;
  loadingDashboard: boolean;
  setLoadingDashboard: Dispatch<SetStateAction<boolean>>;
  playerFormValues: PlayerFormValues;
  setPlayerFormValues: Dispatch<SetStateAction<PlayerFormValues>>;
}

const defaultState = {
  setPopupTitle: (): string => "",
  setPopupState: (): boolean => false,
  popupState: false,
  popupTitle: "",
  exportPopupState: {
    shouldOpen: false,
    title: "",
  },
  setExportPopupState: (): PopupStateType => ({
    shouldOpen: false,
    title: "",
  }),
  modalState: false,
  setModalState: (): boolean => false,
  modalTitle: "",
  setModalTitle: (): string => "",
  setDisplayBack: (): boolean => false,
  setModalStepList: (): StepType[] => [],
  setModalSingleStep: (): JSX.Element | undefined => undefined,
  setCurrentStep: (): number => 1,
  currentStep: 1,
  modalStepList: [],
  uploadFiles: [],
  setUploadFiles: (): UploadFile[] => [],
  abortControllers: [],
  setAbortControllers: (): AbortControllerInfo[] => [],
  dialogState: {
    shouldOpen: false,
    type: "",
  },
  setDialogState: (): DialogStateType => ({
    shouldOpen: false,
    type: "",
  }),
  playerIdToRetrieve: "",
  setPlayerIdToRetrieve: (): string => "",
  uploadInProgress: false,
  setUploadInProgress: (): boolean => false,
  exportInProgress: false,
  setExportInProgress: (): boolean => false,
  loadingDashboard: false,
  setLoadingDashboard: (): boolean => false,
  playerFormValues: emptyPlayerFormValues,
  setPlayerFormValues: (): PlayerFormValues => emptyPlayerFormValues,
};

const AppContext = createContext<AppContextInterface>(defaultState);

export const useAppContext = (): AppContextInterface => useContext(AppContext);

export default AppContext;
