import React from "react";

import { ForgotPasswordForm } from "../../components";

export const ForgotPassword: React.FC = () => {
  return (
    <div
      className="min-h-screen min-w-full bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8"
      data-testid="forgot-root-element"
    >
      <div
        className="sm:mx-auto sm:w-full sm:max-w-md bg-primary-800 sm:rounded-lg sm:shadow-lg py-4"
        data-testid="forgot-header"
      >
        <img
          className="mx-auto h-12 w-auto"
          src="/RebootLogo.png"
          alt="Reboot Motion logo with the words Reboot and Motion stacked on top of each other with the top one bold and bottom one regular weight"
          data-testid="reboot-logo"
        />
        <h2
          className="mt-6 text-center text-3xl font-extrabold text-white"
          data-testid="forgot-heading"
        >
          Forgot Password
        </h2>
      </div>
      <ForgotPasswordForm />
    </div>
  );
};
