import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Credentials, Notebook, RequestedAnalyses } from "../../components";
import { TabContent, TabItem } from "../../components/shared";
import { useAppSelector } from "../../redux/hooks";
import { disableCustomAnalysis } from "../../shared/Functions";

import { TabRouteParams } from "../../types/sharedTypes";

export const AnalyzePage: React.FC = (): JSX.Element => {
  const orgType = useAppSelector((state) => state.user).org.type;
  const defaultActiveTab = disableCustomAnalysis(orgType)
    ? "analyze/time-series"
    : "analyze";
  const { activeTab } = useParams<TabRouteParams>();
  const history = useHistory();

  const [generateCredentials, setGenerateCredentials] =
    useState<boolean>(false);
  const [viewCredentials, setViewCredentials] = useState<boolean>(false);

  useEffect(() => {
    if (!activeTab) {
      history.push(`/${defaultActiveTab}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleTab = (tab: string) => {
    if (activeTab !== tab) {
      if (tab === "analyze") {
        history.push(`/${tab}`);
      } else {
        history.push(`/analyze/${tab}`);
      }
    }
  };

  return (
    <main className="relative pb-20 px-4 z-0 overflow-y-auto w-full">
      <div className="mt-8">
        <div className="max-w-8xl mx-auto w-full flex justify-between items-center pb-2">
          <div className="w-full flex flex-col justify-start px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mt-4 mb-8" data-testid="analyze-header">
              <h2 className="text-3xl leading-6 font-bold text-gray-900">
                Analyze
              </h2>
              <p className="mt-4 text-lg text-gray-400">
                Dive into data with our Biomechanics Analysis tools.
              </p>
            </div>

            <div
              className="w-full border-b border-gray-200"
              data-testid="analyze-tabs-container"
            >
              <ul
                className="-mb-px flex space-x-8"
                data-testid="analyze-page-tabs"
              >
                {!disableCustomAnalysis(orgType) && (
                  <TabItem
                    page="analyze"
                    title="Reboot Analysis"
                    activeTab={activeTab ? activeTab : defaultActiveTab}
                    toggleTab={toggleTab}
                  />
                )}
                <TabItem
                  page="time-series"
                  title="Time Series"
                  activeTab={activeTab ? activeTab : defaultActiveTab}
                  toggleTab={toggleTab}
                />
                <TabItem
                  page="metrics"
                  title="Metrics"
                  activeTab={activeTab ? activeTab : defaultActiveTab}
                  toggleTab={toggleTab}
                />
                <TabItem
                  page="recommendations"
                  title="Recommendations"
                  activeTab={activeTab ? activeTab : defaultActiveTab}
                  toggleTab={toggleTab}
                />
                <TabItem
                  page="gui"
                  title="GUI"
                  activeTab={activeTab ? activeTab : defaultActiveTab}
                  toggleTab={toggleTab}
                />
                <TabItem
                  page="biomechanics-simulation"
                  title="Biomechanics Simulation"
                  activeTab={activeTab ? activeTab : defaultActiveTab}
                  toggleTab={toggleTab}
                />
              </ul>
            </div>
          </div>
        </div>

        <div data-testid="analyze-page-contents">
          <TabContent
            page="analyze"
            activeTab={activeTab ? activeTab : defaultActiveTab}
          >
            <RequestedAnalyses />
          </TabContent>
          <TabContent
            page="time-series"
            activeTab={activeTab ? activeTab : defaultActiveTab}
          >
            <Notebook
              page="time-series"
              content="A tool for analyzing Time Series data; for example joint angles calculated by inverse kinematics, or momentum and energy flow data over time. Examine data points collected in sequence to spot patterns, understand trends, or compare movement mechanics in different situations."
              generateCredentials={generateCredentials}
              setGenerateCredentials={setGenerateCredentials}
              viewCredentials={viewCredentials}
              setViewCredentials={setViewCredentials}
              notebookLink="https://colab.research.google.com/github/RebootMotion/reboot-toolkit/blob/main/examples/RebootMotionAnalysis.ipynb"
            />
            {viewCredentials && <Credentials />}
          </TabContent>
          <TabContent
            page="metrics"
            activeTab={activeTab ? activeTab : defaultActiveTab}
          >
            <Notebook
              page="metrics"
              content="A tool for analyzing Reboot Motion movement-by-movement metrics, for example the peak momentum in each body part or the rotation plane for each body part in a given movement. Examine trends over time or compare metrics in different situations."
              generateCredentials={generateCredentials}
              setGenerateCredentials={setGenerateCredentials}
              viewCredentials={viewCredentials}
              setViewCredentials={setViewCredentials}
              notebookLink="https://colab.research.google.com/github/RebootMotion/reboot-toolkit/blob/main/examples/RebootMotionAnalysisMetrics.ipynb"
            />
            {viewCredentials && <Credentials />}
          </TabContent>
          <TabContent
            page="recommendations"
            activeTab={activeTab ? activeTab : defaultActiveTab}
          >
            <Notebook
              page="recommendations"
              content="A tool to submit a collection of Reboot Motion metrics for a player, and generate a set of recommendations for movement techniques that might help the player improve their performance. For example, generate recommendations that could help a player increase their fastball velocity."
              generateCredentials={generateCredentials}
              setGenerateCredentials={setGenerateCredentials}
              viewCredentials={viewCredentials}
              setViewCredentials={setViewCredentials}
              notebookLink="https://colab.research.google.com/github/RebootMotion/reboot-toolkit/blob/main/examples/Recommendations.ipynb"
            />
            {viewCredentials && <Credentials />}
          </TabContent>
          <TabContent
            page="gui"
            activeTab={activeTab ? activeTab : defaultActiveTab}
          >
            <Notebook
              page="gui"
              content="A graphical user interface for exploratory analysis of Reboot Motion data. It allows data scientists to analyze data and visualize patterns with simple drag-and-drop operations."
              generateCredentials={generateCredentials}
              setGenerateCredentials={setGenerateCredentials}
              viewCredentials={viewCredentials}
              setViewCredentials={setViewCredentials}
              notebookLink="https://colab.research.google.com/github/RebootMotion/reboot-toolkit/blob/main/examples/RebootMotionAnalysisGUI.ipynb"
            />
            {viewCredentials && <Credentials />}
          </TabContent>
          <TabContent
            page="biomechanics-simulation"
            activeTab={activeTab ? activeTab : defaultActiveTab}
          >
            <Notebook
              page="biomechanics-simulation"
              content="A jumping off point for running biomechanics simulations, like inverse and forward dynamics, in the cloud and at scale. Leverages the powerful MuJoCo open source physics engine, which is the first full-featured simulator designed from the ground up for the purpose of model-based optimization, and in particular optimization through contacts."
              generateCredentials={generateCredentials}
              setGenerateCredentials={setGenerateCredentials}
              viewCredentials={viewCredentials}
              setViewCredentials={setViewCredentials}
              notebookLink="https://colab.research.google.com/github/RebootMotion/reboot-toolkit/blob/main/examples_biomechanics/RebootMotionBiomechanicsSimulation.ipynb"
            />
            {viewCredentials && <Credentials />}
          </TabContent>
        </div>
      </div>
    </main>
  );
};
