import React, { useCallback, useEffect, useMemo, useState } from "react";
import { isBrowser } from "react-device-detect";
import { Link } from "react-router-dom";

import { StepType, Table } from "../../components";
import {
  ColumnOneTableCellSkeleton,
  PrimaryButton,
  TableCellSkeleton,
} from "../../components/shared";
import { useAppContext } from "../../context";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { playerSlice } from "../../redux/slices/playerSlice";
import {
  useGetAllPlayersQuery,
  useGetPlayerByIdQuery,
  useGetSessionsByStatusQuery,
} from "../../services";
import { SharedSessionColumns } from "../../shared/columns/SharedSessionColumns";
import { playerListLimit, uploadSessionStepList } from "../../shared/Constants";
import {
  parseCompletedSessions,
  parseInProgressSessions,
} from "../../shared/Functions";
import { Player } from "../../types";

export const Sessions: React.FC = (): JSX.Element => {
  // State and AppContext
  const [selectedPageSize, setSelectedPageSize] = useState(
    sessionStorage.getItem("homeDashboardPageSize") || "10"
  );
  const {
    setModalStepList,
    setModalState,
    setModalTitle,
    setDisplayBack,
    setModalSingleStep,
    playerIdToRetrieve,
    setPlayerIdToRetrieve,
  } = useAppContext();
  const [offsetForPlayerList, setOffsetForPlayerList] = useState(0);
  const [combinedPlayers, setCombinedPlayers] = useState<Player[]>([]);
  const [playerQueryCount, setPlayerQueryCount] = useState(0);

  // Redux calls
  const uploadSessionFormValues = useAppSelector(
    (state) => state.uploadSessionForm
  );
  const orgData = useAppSelector((state) => state.org);
  const storedPlayerList = useAppSelector((state) => state.players.playerList);
  const pendingSessionCount = orgData.sessions.pending;

  const playerCount = orgData.players.total;
  const numberOfQueriesForPlayers = Math.ceil(playerCount / playerListLimit);

  // RTK slice actions
  const dispatch = useAppDispatch();
  const { storePlayers } = playerSlice.actions;

  // RTK Queries
  const { data: pendingSessions, isFetching: fetchingPending } =
    useGetSessionsByStatusQuery(
      {
        status: "pending",
        limit: isBrowser ? 3 : pendingSessionCount,
        offset: 0,
      },
      { skip: pendingSessionCount === 0 }
    );
  const { data: processedSessions, isFetching: fetchingProcessed } =
    useGetSessionsByStatusQuery({
      status: "processed",
      limit: 7,
      offset: 0,
    });
  const {
    data: playerList,
    originalArgs,
    isFetching: fetchingPlayers,
  } = useGetAllPlayersQuery(
    {
      offset: offsetForPlayerList,
      limit: playerListLimit,
    },
    {
      skip:
        storedPlayerList.length === playerCount ||
        playerQueryCount === numberOfQueriesForPlayers,
    }
  );
  const { data: singlePlayer } = useGetPlayerByIdQuery(playerIdToRetrieve, {
    skip: !playerIdToRetrieve,
  });

  // Data processing and parsing
  const inProgressSessionList = parseInProgressSessions(pendingSessions || []);
  const completedSessionList = parseCompletedSessions(processedSessions || []);
  const memoizedSharedSessionColumns = useMemo(SharedSessionColumns, []);

  const processingTableData = useMemo(
    () => (fetchingPending ? Array(3).fill({}) : inProgressSessionList),
    [fetchingPending, inProgressSessionList]
  );

  const processingTableColumns = useMemo(
    () =>
      fetchingPending
        ? memoizedSharedSessionColumns.map((column, index) => ({
            ...column,
            cell:
              index === 0 ? (
                <ColumnOneTableCellSkeleton />
              ) : (
                <TableCellSkeleton />
              ),
          }))
        : memoizedSharedSessionColumns,
    [fetchingPending, memoizedSharedSessionColumns]
  );

  const completedTableData = useMemo(
    () => (fetchingProcessed ? Array(7).fill({}) : completedSessionList),
    [fetchingProcessed, completedSessionList]
  );

  const completedTableColumns = useMemo(
    () =>
      fetchingProcessed
        ? memoizedSharedSessionColumns.map((column, index) => ({
            ...column,
            cell:
              index === 0 ? (
                <ColumnOneTableCellSkeleton />
              ) : (
                <TableCellSkeleton />
              ),
          }))
        : memoizedSharedSessionColumns,
    [fetchingProcessed, memoizedSharedSessionColumns]
  );

  useEffect(() => {
    if (playerList && originalArgs) {
      if (playerList.length === playerListLimit) {
        setOffsetForPlayerList(originalArgs.offset + playerListLimit);
        setCombinedPlayers(combinedPlayers.concat(playerList));
        setPlayerQueryCount(playerQueryCount + 1);
      } else if (playerList.length < playerListLimit) {
        setCombinedPlayers(combinedPlayers.concat(playerList));
        setOffsetForPlayerList(0);
        setPlayerQueryCount(playerQueryCount + 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerList]);

  useEffect(() => {
    if (storedPlayerList.length !== playerCount && combinedPlayers) {
      dispatch(storePlayers(combinedPlayers));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [combinedPlayers]);

  useEffect(() => {
    if (singlePlayer && storedPlayerList) {
      const updatedPlayerList = storedPlayerList
        .filter((player) => player.orgPlayerId !== playerIdToRetrieve)
        .concat(singlePlayer);
      dispatch(storePlayers(updatedPlayerList));
      setPlayerIdToRetrieve("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singlePlayer]);

  const stepList: StepType[] = useMemo((): StepType[] => {
    if (orgData && uploadSessionFormValues) {
      return uploadSessionStepList({
        playerList: storedPlayerList,
        sessionFormValues: uploadSessionFormValues,
        orgData: orgData,
      });
    } else {
      return [];
    }
  }, [orgData, uploadSessionFormValues, storedPlayerList]);

  const openModal = () => {
    setModalSingleStep(undefined);
    const stepTitleNode = document.querySelector("#step-1-title");
    if (stepTitleNode) {
      stepTitleNode.innerHTML = `<div>Select/Add Player</div>`;
    }
    setModalStepList(stepList);
    setModalTitle("Upload Data");
    setModalState(true);
  };

  const checkIfNextButtonIsEnabled = useCallback(() => {
    if (stepList.length && !stepList[0].disableNext) {
      setModalStepList(stepList);
    }
  }, [setModalStepList, stepList]);

  useEffect(() => {
    setDisplayBack && setDisplayBack(false);
    checkIfNextButtonIsEnabled();
  }, [setDisplayBack, checkIfNextButtonIsEnabled]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="relative pb-20 z-0 overflow-y-auto w-full">
      <div
        className="hidden lg:block mt-8 px-4"
        data-testid="desktop-home-dashboard-container"
      >
        <div className="max-w-8xl mx-auto sm:flex sm:justify-between items-center sm:pb-2">
          <div className="flex justify-start items-center">
            <h2
              className="max-w-7xl sm:mt-4 px-0 text-2xl sm:text-3xl leading-6 font-bold text-gray-900 sm:px-6 lg:px-8 lg:pb-6"
              data-testid="sessions-processing-header"
            >
              Sessions Processing
            </h2>
            {fetchingPlayers ? (
              <p className="text-gray-400 cursor-not-allowed ">View All</p>
            ) : (
              <Link
                to="/processing-sessions"
                className="text-blue-800 hover:text-blue-600 "
                data-testid="sessions-processing-view-all-link"
              >
                View All
              </Link>
            )}
          </div>
          <PrimaryButton
            className="mr-8"
            onClick={() => openModal()}
            data-testid="upload-player-data-button"
            disabled={fetchingPlayers}
          >
            Upload Player Data
          </PrimaryButton>
        </div>
        <div
          className="min-w-max"
          data-testid="sessions-processing-table-container"
        >
          <Table
            columns={processingTableColumns}
            data={processingTableData}
            data-testid="sessions-processing-table"
            selectedPageSize={selectedPageSize}
            setSelectedPageSize={setSelectedPageSize}
            displayFooter={false}
            fetching={fetchingPending}
            customRowClassName="processing-sessions-row"
            hideSearch={true}
          />
        </div>
        <div className="max-w-8xl mx-auto justify-between items-center pb-2 pt-8">
          <div className="flex justify-start items-center">
            <h2
              className="mt-4 px-4 text-3xl leading-6 font-bold text-gray-900 sm:px-6 lg:px-8 lg:pb-6"
              data-testid="sessions-completed-header"
            >
              Sessions Completed
            </h2>
            <Link
              to="/completed-sessions"
              className="text-blue-800 hover:text-blue-600"
              data-testid="sessions-completed-view-all-link"
            >
              View All
            </Link>
          </div>
        </div>
        <div
          className="min-w-max"
          data-testid="sessions-completed-table-container"
        >
          <Table
            columns={completedTableColumns}
            data={completedTableData}
            data-testid="sessions-completed-table"
            selectedPageSize={selectedPageSize}
            setSelectedPageSize={setSelectedPageSize}
            displayFooter={false}
            fetching={fetchingProcessed}
            customRowClassName="completed-sessions-row"
            hideSearch={true}
          />
        </div>
      </div>
    </main>
  );
};
