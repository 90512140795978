import { createSlice } from "@reduxjs/toolkit";
import { addHours } from "date-fns";

import { User } from "../../types";
import { RootState } from "../store";

const initialState: User = {
  id: 0,
  firstName: "",
  lastName: "",
  email: "",
  org: {
    id: "",
    name: "",
    type: "",
    externalId: "",
    details: {
      movementTypes: [],
      sessionTypes: [],
      sessionVenues: [],
      mocapTypes: [],
    },
    players: {
      total: 0,
    },
    sessions: {
      total: 0,
      pending: 0,
      complete: 0,
    },
  },
  errorMessage: "",
  token: "",
  refreshToken: "",
  loginTime: "",
  tokenExpiry: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearLoginState: (state) => {
      state = initialState;
      return state;
    },
    setCredentials: (state, action) => {
      state.id = action.payload.id;
      state.token = action.payload.accessToken;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.org.id = action.payload.org.id;
      state.org.name = action.payload.org.name;
      state.org.type = action.payload.org.type;
      state.loginTime = new Date().toISOString();
      state.tokenExpiry = addHours(new Date(), 23).toISOString();
    },
    logoutUser: () => undefined,
  },
});

export const selectUser = (state: RootState): User => state.user;
