import React from "react";
import { PlacesType, Tooltip } from "react-tooltip";

import { toTitleCase } from "../../shared/Functions";

type TooltipProps = {
  anchorId: string;
  type: string;
  info: string;
  place: PlacesType;
};

export const CustomTooltip: React.FC<TooltipProps> = ({
  anchorId,
  type,
  info,
  place,
}: TooltipProps): JSX.Element => {
  const customStyles = {
    background: "#FFFFFF",
    borderRadius: "8px",
    boxShadow:
      "0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)",
    width: "512px",
    padding: "24px",
  };

  return (
    <div data-testid={`${type}-tooltip-container`}>
      <Tooltip id={anchorId} place={place} style={customStyles}>
        <h2
          data-testid={`${type}-tooltip-title`}
          className="text-gray-900 text-lg font-medium mb-4 text-left"
        >
          {`What is a ${toTitleCase(type)} Segment?`}
        </h2>
        <p
          data-testid={`${type}-tooltip-content`}
          className="text-gray-500 text-sm font-[450] text-left"
        >
          {info}
        </p>
      </Tooltip>
    </div>
  );
};
