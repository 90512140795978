/** @module Containers */

import React, { useEffect, useMemo, useState } from "react";

import { Table } from "../../components";
import {
  ColumnOneTableCellSkeleton,
  TableCellSkeleton,
} from "../../components/shared";
import { useAppContext } from "../../context";
import { useGetSessionsByStatusQuery } from "../../services/session";
import { SharedSessionColumns } from "../../shared/columns/SharedSessionColumns";
import { sessionListLimit } from "../../shared/Constants";
import { parseCompletedSessions } from "../../shared/Functions";
import { Session } from "../../types";

const CompletedSessions: React.FC = (): JSX.Element => {
  // State and AppContext
  const { setDisplayBack } = useAppContext();
  const [selectedPageSize, setSelectedPageSize] = useState(
    sessionStorage.getItem("completedSessionPageSize") || "10"
  );
  const [sessionList, setSessionList] = useState<Session[]>([]);
  const [offsetForSessionList, setOffsetForSessionList] = useState(0);
  const [endOfList, setEndOfList] = useState(false);

  // RTK Queries
  const {
    data: completedSessions,
    isFetching: fetchingSessions,
    originalArgs,
  } = useGetSessionsByStatusQuery({
    status: "processed",
    limit: sessionListLimit,
    offset: offsetForSessionList,
  });

  /** Sorts the completed session list by session date in reverse order by default, most recent to oldest */
  const completedSessionList = parseCompletedSessions(sessionList || []);

  const memoizedSharedSessionColumns = useMemo(SharedSessionColumns, []);

  const tableData = useMemo(
    () => (fetchingSessions ? Array(10).fill({}) : completedSessionList),
    [fetchingSessions, completedSessionList]
  );

  const tableColumns = useMemo(
    () =>
      fetchingSessions
        ? memoizedSharedSessionColumns.map((column, index) => ({
            ...column,
            cell:
              index === 0 ? (
                <ColumnOneTableCellSkeleton />
              ) : (
                <TableCellSkeleton />
              ),
          }))
        : memoizedSharedSessionColumns,
    [fetchingSessions, memoizedSharedSessionColumns]
  );

  useEffect(() => {
    setDisplayBack && setDisplayBack(true);
    if (selectedPageSize) {
      sessionStorage.setItem("completedSessionPageSize", selectedPageSize);
    }
  }, [selectedPageSize, setDisplayBack]);

  useEffect(() => {
    if (completedSessions && originalArgs) {
      if (!endOfList && completedSessions.length === sessionListLimit) {
        setOffsetForSessionList(originalArgs.offset + sessionListLimit);
        setSessionList(sessionList.concat(completedSessions));
      } else if (!endOfList && completedSessions.length < sessionListLimit) {
        setSessionList(sessionList.concat(completedSessions));
        setEndOfList(true);
        setOffsetForSessionList(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedSessions]);

  return (
    <main className="relative pb-20 px-4 z-0 overflow-y-auto w-full">
      <div className="mt-8">
        <div className="max-w-8xl mx-auto flex justify-between items-center pb-2">
          <div className="flex justify-start">
            <h2
              className="mt-4 px-4 text-3xl leading-6 font-bold text-gray-900 sm:px-6 lg:px-8 lg:pb-6"
              data-testid="completed-sessions-header"
            >
              Sessions Completed
            </h2>
          </div>
        </div>
        <div
          className="block min-w-max mt-3"
          data-testid="completed-sessions-table-container"
        >
          <Table
            columns={tableColumns}
            data={tableData}
            data-testid="completed-sessions-table"
            selectedPageSize={selectedPageSize}
            setSelectedPageSize={setSelectedPageSize}
            displayFooter={true}
            fetching={fetchingSessions}
            customRowClassName="completed-sessions-table-row"
            filters={["sessionDate"]}
          />
        </div>
      </div>
    </main>
  );
};

export default CompletedSessions;
