import { Popover } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import React from "react";

import {
  ButtonDetails,
  ButtonDetailsWithDropdown,
} from "../../../types/sharedTypes";

type DropdownButtonProps = {
  buttonDetails: ButtonDetails | ButtonDetailsWithDropdown;
  disableButton?: boolean;
};

export const DropdownButton: React.FC<DropdownButtonProps> = ({
  buttonDetails,
  disableButton,
}: DropdownButtonProps) => {
  return (
    <Popover className="relative" data-testid="dropdown-button-container">
      <Popover.Button
        className="flex justify-center items-center text-gray-700 px-4 py-2 border rounded-md shadow-sm text-sm font-bold border-[#D1D5DB] bg-white disabled:text-[#CACCCF] disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-white"
        data-testid={buttonDetails.dataTestid}
        disabled={disableButton}
      >
        {buttonDetails.Icon && (
          <buttonDetails.Icon className="mr-2 h-5 w-5 text-secondary-800 stroke-2" />
        )}
        {buttonDetails.text}
        <ChevronDownIcon className="ml-2 h-5 w-5 stroke-2" />
      </Popover.Button>

      <Popover.Panel
        className="p-2 origin-top-right absolute right-0 mt-2 w-44 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
        data-testid="dropdown-button-list-container"
      >
        {(buttonDetails as ButtonDetailsWithDropdown).dropdownList &&
          (buttonDetails as ButtonDetailsWithDropdown).dropdownList.map(
            (item, index) => (
              <Popover.Button
                as="div"
                key={index}
                className="w-full flex items-center px-2 py-1 hover:bg-[#F5A623]/[0.06] hover:text-[#FB6A07] active:bg-[#FF7A1F]/[0.12] active:text-[#FB6A07]"
              >
                <button
                  className="flex items-center py-1 text-gray-700 w-full"
                  onClick={() => buttonDetails.action(item.value)}
                  data-testid={`${item.value}-dropdown-button`}
                >
                  {item.Icon && <item.Icon className="mr-2 h-5 w-5 stroke-2" />}
                  <span className="text-gray-700 flex flex-col py-1 text-sm">
                    {item.name}
                  </span>
                </button>
              </Popover.Button>
            )
          )}
      </Popover.Panel>
    </Popover>
  );
};
