/** @module Components */

import React from "react";
import { Link, useHistory } from "react-router-dom";

import { ReactComponent as OrangeArrowLeft } from "../../assets/OrangeArrowLeft.svg";
import { User } from "../../types";
import { DropdownMenu } from "../DropdownMenu/DropdownMenu";

/** @category Prop Types */
export type PageHeaderProps = {
  /** The currently logged in user */
  user: User;
  /** Whether or not to display the back button and text */
  displayBack: boolean;
};

export const PageHeader: React.FC<PageHeaderProps> = ({
  user,
  displayBack,
}: PageHeaderProps) => {
  const history = useHistory();

  return (
    <div
      className="flex items-center justify-between w-full h-full border-b-2 border-gray-200"
      id="header-container"
      data-testid="header-container"
    >
      <div className="flex w-3/5 justify-start">
        <div
          className="pl-4 sm:pl-8 flex items-center static w-16 h-full sm:mr-4"
          id="header-logo-container"
          data-testid="header-logo-container"
        >
          <Link to="/">
            <svg
              width="30"
              height="35"
              viewBox="0 0 26 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              data-testid="reboot-motion-logo"
            >
              <path
                d="M18.29 19.7266C20.0521 18.7156 21.4338 17.4005 22.4378 15.7813C23.4393 14.1621 23.9388 12.4148 23.9388 10.5342C23.9388 7.41314 22.7725 4.87629 20.4425 2.92631C18.11 0.976326 14.525 0 9.68503 0C6.39669 0 3.16919 0.17339 0.00253589 0.520173V7.95465H9.6039V5.34311C9.6039 4.8016 10.4279 4.53218 10.9096 4.91364L16.6167 9.43781C17.1719 9.87796 17.1719 10.7529 16.6167 11.1931L10.9096 15.7172C10.4279 16.0987 9.6039 15.8293 9.6039 15.2878V12.6762H0V31.9973H7.62126V21.5912C9.05626 21.6499 9.99688 21.6792 10.4456 21.6792C11.0136 21.6792 11.4471 21.6659 11.7463 21.6365L17.3494 32H26L18.29 19.7266Z"
                fill="#FB6A07"
              />
            </svg>
          </Link>
        </div>
        {displayBack && (
          <div
            className="flex justify-start w-2/12 pl-8 items-center"
            data-testid="back-button-container"
          >
            <button
              onClick={() => history.goBack()}
              className="w-full flex"
              data-testid="back-button-icon"
            >
              <OrangeArrowLeft className="flex-shrink-0" />
              <p
                className="text-lg font-bold pl-4 "
                data-testid="back-button-text"
              >
                Back
              </p>
            </button>
          </div>
        )}
      </div>

      <div
        className="lg:flex lg:justify-center pr-4 lg:pr-8"
        data-testid="menu-container"
      >
        <p
          className="hidden lg:flex text-gray-600 pr-1"
          data-testid="welcome-message"
        >
          Welcome back,
        </p>
        <p
          className="hidden lg:flex text-gray-900 font-bold"
          data-testid="user-name"
        >
          {user.firstName} {user.lastName}
        </p>
        <DropdownMenu user={user} />
      </div>
    </div>
  );
};
